<div class="overLayDiv z-99999" [ngClass]="{'active': data.showPanel == true}" ></div>
<div id="rightSideBar">
  <div class="rightSidePanel z-99999" [ngClass]="{'active': data.showPanel == true}" >
    <header class="py-1 d-flex justify-content-between align-items-center height-60px">
      <h2>{{data.title}}</h2>
    </header>
    <div class="clear"></div>
    <!-- Please do not change any thing before speak to me - Nitesh -->
    <!-- <div class="block mt-2"
        *ngIf="data.showReviewConfirmContinuePanel && (data && data.message && data.message.length == 0)">
        <div class="row">
          <h6 class="text-left margin-top-7">
            <span class="padding-right-5">
              <em class="fas fa-exclamation-circle color-red align-middle"></em>
            </span>{{ data.showReviewConfirmContinuePanelText }}
          </h6>
        </div>
      </div> -->
    <!-- <div class="block mt-2"
        *ngIf="data.showReviewConfirmContinuePanel && (data && data.message && data.message.length > 0)">
        <div class="text-left pointer-events-none f-16">
          <span class="padding-right-5 text-left"></span>{{ data.showReviewConfirmContinuePanelText }}
        </div>
      </div> -->

    <div [ngClass]="setClasses()">
      <span *ngIf="(data && data.message && data.message.length > 1)">
        <strong>{{ 'note' | translate}}</strong>
      </span>
      <div class="row" *ngIf="data.htmlMessage.length > 1">
        <ul class="info-panel" *ngIf="data.htmlMessage.length > 1">
          <li class="border-bottom" *ngFor="let item of data.htmlMessage">
            <p class="text-charcoal-black lH-25">
              <span *ngIf="item.iconClass"><i [ngClass]="item.iconClass" aria-hidden="true"></i></span>
              <span class="padding-left-5" [innerHTML]="item.textHtml"></span>
            </p>
          </li>
        </ul>
      </div>
      <div class="row" *ngIf="data.htmlMessage.length == 0 && data.message.length > 1">
        <ul class="info-panel" *ngIf="data.message.length > 1">
          <li class="border-bottom" *ngFor="let item of data.message">
            <p class="text-charcoal-black lH-25">
              <span><i class="fas fa-exclamation-circle color-red align-middle" aria-hidden="true"></i></span>
              <span class="padding-left-5" [innerHTML]="item"></span>
            </p>
          </li>
        </ul>
      </div>
      <div class="row" *ngIf="data.htmlMessage.length == 0 && data.message.length == 1">     
        <ul class="info-panel">
          <li class="border-bottom" *ngFor="let item of data.message">
            <p class="text-charcoal-black lH-28">
              <span *ngIf="!hideInfoIcon"><i class="fas fa-exclamation-circle color-red align-middle"
                  aria-hidden="true"></i></span>
              <span class="padding-left-5" [ngClass]="singleMessageTextClass" [innerHTML]="item"></span>
            </p>
          </li>
        </ul>
      </div>
      <div class="row" *ngIf="data.htmlMessage.length == 0 && data.message.length == 0">     
        <ul class="info-panel">
          <li class="border-bottom">
            <p class="text-charcoal-black lH-28">
              <span><i class="fas fa-exclamation-circle color-red align-middle" aria-hidden="true"></i></span>
              <span class="padding-left-5" [innerHTML]="sanitizerHtml(data.showReviewConfirmContinuePanelText)"></span>
            </p>
          </li>
        </ul>
      </div>
    </div>

    <div class="d-flex position-absolute bottom-0 w-100pc justify-content-center bg-white padding-15" 
    *ngIf="this.data.btnArray.length == 0">
      <span class="margin-right-15" *ngIf="this.data.btnCancelToolTipText!=''">
        <button type="button" class="btn btn-outline-primary bg-white fw-600 height-38px"
          (click)="dismiss()">{{this.data.btnCancelToolTipText}}
        </button>
      </span>
      <span class="margin-right-0" *ngIf="this.data.btnOkToolTipText!=''">
        <button type="button" class="btn btn-primary" (click)="accept()">{{this.data.btnOkToolTipText}}
        </button>
      </span>
    </div>

    <div class="d-flex position-absolute bottom-0 w-100pc justify-content-center bg-white padding-15" 
    *ngIf="this.data.btnArray.length > 0">
      <span [ngClass]="item.btnMarginClass" *ngFor="let item of data.btnArray">
        <button type="button" [ngClass]="item.btnClass"
          (click)="fnSendAction(item.btnAction)">{{item.btnText}}
        </button>
      </span>
      
    </div>
  </div>
</div>
