import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import MetisMenu from "metismenujs";
import { Router, NavigationEnd } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { MENU } from "./menu";
import { MenuItem } from "./menu.model";

import { SIDEBAR_COLOR } from "../layouts.model";
import { OrganisationSettingService } from "src/app/_services/organisation-setting/organisation-setting.service";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { Claims } from "src/app/_models/common/claims";
import { FunctionCategoryEnum } from "src/app/_models";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})

/**
 * Sidebar Component
 */
export class SidebarComponent implements OnInit {
  @ViewChild("sideMenu") sideMenu!: ElementRef;
  menu: any;
  menuItems: MenuItem[] = [];

  isSidebar: any;
  claims: Claims;
  selectedMenuId: number = 0;
  constructor(
    private router: Router,
    public translate: TranslateService,
    private organisationSettingService: OrganisationSettingService,
    private claimsService: ClaimsService
  ) {

    this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
    });
    this.selectedMenuId =
      this.routeIsActive("/claimant") ||
      this.routeIsActive("/approver") ||
      this.routeIsActive("/financeapprover") ||
      this.routeIsActive("/pay") ||
      this.routeIsActive("/export") ||
      this.routeIsActive("/universalimport/ccuploadfile") ||
      this.routeIsActive("/card") ||
      this.routeIsActive("/ManageSubscription") ||
      this.routeIsActive("/referandearn") ||
      this.routeIsActive("/support") ||
      this.routeIsActive("/changepassword") ||
      this.routeIsActive("/calander")
        ? -1
        : this.selectedMenuId;

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        //this._activateMenuDropdown();
        this.selectedMenuId =
          this.routeIsActive("/claimant") ||
          this.routeIsActive("/approver") ||
          this.routeIsActive("/financeapprover") ||
          this.routeIsActive("/pay") ||
          this.routeIsActive("/export") ||
          this.routeIsActive("/universalimport/ccuploadfile") ||
          this.routeIsActive("/card") ||
          this.routeIsActive("/ManageSubscription") ||
          this.routeIsActive("/referandearn") ||
          this.routeIsActive("/support") ||
          this.routeIsActive("/changepassword") ||
          this.routeIsActive("/calander")
            ? -1
            : this.selectedMenuId;

        if (localStorage.getItem("backFromPolicy") == "1") {
          this.selectedMenuId = 6;
          setTimeout(() => {
            this.selectedMenuId = this.claimsService.selectedPackageCategory =
              this.selectedMenuId;
            this.claimsService.updateSelectedPackageCategory(
              this.selectedMenuId
            );
            localStorage.removeItem("backFromPolicy");
          }, 100);
        } else if (localStorage.getItem("fromExploreAppEssentialPack") == "1") {
          this.selectedMenuId = 1;
          setTimeout(() => {
            this.selectedMenuId = this.claimsService.selectedPackageCategory =
              this.selectedMenuId;
            this.claimsService.updateSelectedPackageCategory(
              this.selectedMenuId
            );
            localStorage.removeItem("fromExploreAppEssentialPack");
          }, 100);
        } else if(localStorage.getItem("ExpenseCategories") == "1"){
          this.selectedMenuId = FunctionCategoryEnum.ExpenseCategories;
          setTimeout(() => {
            this.selectedMenuId = this.claimsService.selectedPackageCategory =
              this.selectedMenuId;
            this.claimsService.updateSelectedPackageCategory(
              this.selectedMenuId
            );
            localStorage.removeItem("ExpenseCategories");
          }, 100);
        }
        else if (localStorage.getItem("forSpecialCategory") == "1") {
          this.selectedMenuId = FunctionCategoryEnum.ExpenseCategories;
          setTimeout(() => {
            this.selectedMenuId = this.claimsService.selectedPackageCategory =
              this.selectedMenuId;
            this.claimsService.updateSelectedPackageCategory(
              this.selectedMenuId
            );
            localStorage.removeItem("forSpecialCategory");
          }, 100);
        }
      }
    });
  }

  routeIsActive(routePath: string) {
    let currentUrl = this.router.url;
    return currentUrl.startsWith(routePath);
  }

  ngOnInit(): void {
    this.menuItems = []; //MENU;
    this.getMySubscriptionSettings();
    this.isSidebar = SIDEBAR_COLOR;
    if (this.isSidebar === "dark") {
      document.body.setAttribute("data-sidebar", "dark");
    }
  }

  navigateToSettings(item: MenuItem) {
    this.selectedMenuId = item.id;
    if (item.id == 0) {
      this.router.navigate([""]);
    } else {
      this.selectedMenuId = this.claimsService.selectedPackageCategory =
        item.id;
      this.claimsService.updateSelectedPackageCategory(item.id);
      //this._activateMenuDropdown();
      this.router.navigate(["/settings"]);
    }
  }

  getMySubscriptionSettings() {
    let functionDetails = [];
    this.organisationSettingService
      .GetOrganisationSettingList()
      .subscribe((data) => {
        if (data.statusCode == 0) {
          let item = {
            id: 0,
            label: "Dashboard",
            link: "",
            isTitle: false,
            icon: "../../svg-icons/functioncategory/inactive/dashboard",
            iconInactive: "../../svg-icons/functioncategory/active/dashboard",
          };

          item["subItems"] = [];
          this.menuItems.push(item);

          data.responseInfo.functionCategoryDetail.forEach((x) => {

            if (x.functionCategoryId == 18 && this.claims.IsAPAutomationAllow) {
              let item = {
                id: x.functionCategoryId,
                label: x.functionCategoryName,
                isTitle: false,
                link: "/settings",
                icon:
                  "../../svg-icons/functioncategory/inactive/functioncategory_" +
                  x.functionCategoryId,
                iconInactive:
                  "../../svg-icons/functioncategory/active/functioncategory_" +
                  x.functionCategoryId,
                isEssentialPackage:
                  x.functionPackageDetail.filter((x) => x.isEssential).length >
                  0,
              };

              item["subItems"] = [];
              this.menuItems.push(item);
            }
            else if (x.functionCategoryId != 16 && x.functionCategoryId != 17 && x.functionCategoryId != 18) {
              let item = {
                id: x.functionCategoryId,
                label: x.functionCategoryName,
                isTitle: false,
                link: "/settings",
                icon:
                  "../../svg-icons/functioncategory/inactive/functioncategory_" +
                  x.functionCategoryId,
                iconInactive:
                  "../../svg-icons/functioncategory/active/functioncategory_" +
                  x.functionCategoryId,
                isEssentialPackage:
                  x.functionPackageDetail.filter((x) => x.isEssential).length >
                  0,
              };

              item["subItems"] = [];
              this.menuItems.push(item);

            }
          });
        }
      });
  }

  /**
   * Initialize
   */
  initialize(): void {
    this.menuItems = MENU;
  }

  /***
   * Activate droup down set
   */
  ngAfterViewInit() {
    this.menu = new MetisMenu("#side-menu");
    //this._activateMenuDropdown();
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className: any) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this._removeAllClass("mm-active");
    this._removeAllClass("mm-show");
    const links: any = document.getElementsByClassName("side-nav-link-ref");
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      menuItemEl = links[paths.indexOf(item)];
    } else {
      menuItemEl = links[itemIndex];
    }
    if (menuItemEl) {
      menuItemEl.classList.add("active");
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add("mm-active");
        const parent2El = parentEl.parentElement.closest("ul");
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.add("mm-show");
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== "side-menu") {
            parent3El.classList.add("mm-active");
            const childAnchor = parent3El.querySelector(".has-arrow");
            const childDropdown = parent3El.querySelector(".has-dropdown");
            if (childAnchor) {
              childAnchor.classList.add("mm-active");
            }
            if (childDropdown) {
              childDropdown.classList.add("mm-active");
            }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== "side-menu") {
              parent4El.classList.add("mm-show");
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== "side-menu") {
                parent5El.classList.add("mm-active");
                const childanchor = parent5El.querySelector(".is-parent");
                if (childanchor && parent5El.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  }
}
