
<div class="row mt-3" *ngIf="model.isMileageExpense">
    <div class="col-md-12 no-padding-right-left" *ngIf="showMileageSection">
        <div class="row margin-top-0 expensePolicy-CP-FP1" *ngIf="fnGetActiveMileageTab()">
            <div class="nav-pills-container nav-pills-container-addStyles" *ngIf="this.fnIsMileageGoogleExpense()">
                <ul class="nav nav-pills justify-content-start padding-left-0">
                    <li (click)="fnOnRoundTripClick()">
                        <a data-bs-toggle="pill" id="nav-roundTrip-tab" 
                        [ngClass]="{ 'active': activeTab == 'nav-roundTrip' }"
                            class="padding-bottom-10 ">
                            {{ 'round_trip' | translate }}
                        </a>
                    </li>
                    <li (click)="fnOnMultipleLegsClick()" [ngClass]="{'disabled':fncheckMultipleVehicle()}">
                        <a data-bs-toggle="pill" id="nav-multipleLeg-tab" class="padding-bottom-10" 
                        [ngClass]="{ 'active': activeTab == 'nav-multipleLeg' }">
                        {{ 'multiple_legs' | translate }}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="tab-content margin-top-5 bg-white box-shadow">
                <div id="nav-roundTrip" class="tab-pane fade padding-top-20 " 
                [ngClass]="{ 'show active': activeTab == 'nav-roundTrip' }">
                    <div class="row margin-top-0">
                        <div class="col-lg-5 padding-left-0">
                            <div class="input-group">

                                  <span>
                                    <input id="fromLocation"  autocomplete="off" type="text" class="form-control"
                                       (input)="onTextInput('fromLocation')" (click)="fnOnInputClick('fromLocation')"
                                        (keyup)="fnSearchAddress('fromLocation',$event.target.value)" (blur)="fnCloseGoogleDropdown('fromLocation')" [(ngModel)]="model.fromLocation"
                                        (keypress)="fnKeypress('fromLocation',$event.target.value)" (keydown)="fnFromKeyDown()"
                                        [ngClass]="{ 'has-value': model.fromLocation , 'padding-right-30' : fnIsMileageGoogleExpense(),
                                        'disabled': model.isEditMode && model.baseCategoryId == 3 && !model.isExpenseCategoryActive}"
                                        >
                            
                                    <label for="ExpenseCategory">
                                        {{ 'text_from' | translate }}{{gm_lable}}<span>*</span>
                                    </label>
                            
                                    <span class="icons" *ngIf="fnIsMileageGoogleExpense() && !isFromDelIconDisplay"
                                     (click)="fnSearchPlacePrediction('fromLocation')">
                                        <a>
                                            <i class="fas fa-search" aria-hidden="true"></i>
                                        </a>
                                    </span>
                                    <span class="icons" *ngIf="isFromDelIconDisplay" (click)="fnDeleteText('fromLocation')">
                                        <a>
                                            <i class="fas fa-times-circle color-light-grey" aria-hidden="true"></i>
                                        </a>
                                    </span>
                            
                                    <div class="dropdown-menu dropdown-menu-list margin" [ngClass]="{ active: isFromHomeOfcDropdownVisible }">
                                        <ul>
                                            <li (click)="fnSetAddressFromTriangulation(model.mileage.userHomeAddressInfo,'fromLocation')">
                                                <p>
                                                    <strong>{{'home' | translate }}</strong>
                                                </p>
                                                <p>
                                                    {{model.mileage?.userHomeAddressInfo?.address}}
                                                </p>
                                            </li>
                                            <li (click)="fnSetAddressFromTriangulation(model.mileage.userOfficeAddressInfo,'fromLocation')">
                                                <p>
                                                    <strong>{{'office' | translate }}</strong>
                                                </p>
                                                <p>
                                                    {{model.mileage?.userOfficeAddressInfo?.address}}
                                                </p>
                                            </li>
                                            <li *ngIf="model.mileage?.isOtherOfficeAvailable" 
                                             (click)="openOfficeAddPanel('fromLocation')" style="margin-top: 5px;">
                                                <p>
                                                    <strong>{{'office_address_other' | translate }}</strong>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="dropdown-menu dropdown-menu-list" [ngClass]="{ active: isFromGoogleDropdownVisible }">
                                        <ul *ngFor="let item of googleDynamicAddressList">
                                            <li (click)="fnSetAddress(item,'fromLocation')"> {{item.description}} </li>
                                        </ul>
                                    </div>
                            
                                    <div class="dropdown-menu dropdown-menu-list" [ngClass]="{ active: isFromGoogleAddressNotFound }">
                                        <ul>
                                            <li>{{'no_match_found' | translate }}</li>
                                        </ul>
                                    </div>
                            
                                    <div *ngIf="formControl.errors && formControl.errors.required && isFormSubmitted" class="alert-danger">
                                        <span *ngIf="!formControl.value || !formControl.value.trim()">
                                            <b>{{ "label_Required" | translate }}</b>
                                        </span>
                                    </div>
                                </span>
                                <div class="float-left w-100pc f-15" 
                                *ngIf="model.baseCategoryId == 3 &&
                                model.mileage.googleCallsInfo && model.mileage.googleCallsInfo.notesForGoogle && isFromGoogleNotesDisplay">
                                    <div class="float-left text-left">
                                        <small class="color-red">
                                            {{ model.mileage.googleCallsInfo.notesForGoogle }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <span class="padding-left-0 align-middle f-12 text-logo-blue" [ngClass]="{'disabled':fncheckMultipleVehicle()}"
                            (click)="fnOpenSelectRecentJourneyPanel()">
                                <a class="text-underline"> 
                                    <span>
                                        <img src="https://qa.expenseondemand.com/assets/svg-icons/recent-journey-claimant.svg" alt="" class="svg-15">
                                    </span>
                                    {{ 'recent_journeys' | translate }}
                                </a>
                            </span>
                        </div>
                        <div class="col-lg-5 no-padding-right-left">
                            <div class="input-group">
                                <span>
                                    <input id="toLocation"  autocomplete="off" type="text" class="form-control"
                                       (input)="onTextInput('toLocation')" (click)="fnOnInputClick('toLocation')"
                                        (keyup)="fnSearchAddress('toLocation',$event.target.value)" (blur)="fnCloseGoogleDropdown('toLocation')" [(ngModel)]="model.toLocation"
                                        (keypress)="fnKeypress('toLocation',$event.target.value)" (keydown)="fnToKeyDown()"
                                        [ngClass]="{ 'has-value': model.toLocation , 'padding-right-30' : fnIsMileageGoogleExpense(),
                                        'disabled': model.isEditMode && model.baseCategoryId == 3 && !model.isExpenseCategoryActive }">
                            
                                    <label for="ExpenseCategory">
                                        {{ 'text_to' | translate }}{{gm_lable}}<span>*</span>
                                    </label>
                            
                                    <span class="icons" *ngIf="fnIsMileageGoogleExpense() && !isToDelIconDisplay"
                                     (click)="fnSearchPlacePrediction('toLocation')">
                                        <a>
                                            <i class="fas fa-search" aria-hidden="true"></i>
                                        </a>
                                    </span>
                                    <span class="icons" *ngIf="isToDelIconDisplay" (click)="fnDeleteText('toLocation')">
                                        <a>
                                            <i class="fas fa-times-circle color-light-grey" aria-hidden="true"></i>
                                        </a>
                                    </span>
                            
                                    <div class="dropdown-menu dropdown-menu-list" [ngClass]="{ active: isToHomeOfcDropdownVisible }">
                                        <ul>
                                            <li (click)="fnSetAddressFromTriangulation(model.mileage.userHomeAddressInfo,'toLocation')">
                                                <p>
                                                    <strong>{{'home' | translate }}</strong>
                                                </p>
                                                <p>
                                                    {{model.mileage?.userHomeAddressInfo?.address}}
                                                </p>
                                            </li>
                                            <li (click)="fnSetAddressFromTriangulation(model.mileage.userOfficeAddressInfo,'toLocation')">
                                                <p>
                                                    <strong>{{'office' | translate }}</strong>
                                                </p>
                                                <p>
                                                    {{model.mileage?.userOfficeAddressInfo?.address}}
                                                </p>
                                            </li>
                                            <li *ngIf="model.mileage?.isOtherOfficeAvailable"
                                             (click)="openOfficeAddPanel('toLocation')" style="margin-top: 5px;">
                                                <p>
                                                    <strong>{{'office_address_other' | translate }}</strong>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="dropdown-menu dropdown-menu-list" [ngClass]="{ active: isToGoogleDropdownVisible }">
                                        <ul *ngFor="let item of googleDynamicAddressList">
                                            <li (click)="fnSetAddress(item,'toLocation')"> {{item.description}} </li>
                                        </ul>
                                    </div>
                            
                                    <div class="dropdown-menu dropdown-menu-list" [ngClass]="{ active: isToGoogleAddressNotFound }">
                                        <ul>
                                            <li>{{'no_match_found' | translate }}</li>
                                        </ul>
                                    </div>
                            
                                    <div *ngIf="formControl.errors && formControl.errors.required && isFormSubmitted" class="alert-danger">
                                        <span *ngIf="!formControl.value || !formControl.value.trim()">
                                            <b>{{ "label_Required" | translate }}</b>
                                        </span>
                                    </div>
                                </span>
                                <div class="float-left w-100pc f-15" 
                                *ngIf="model.baseCategoryId == 3 && 
                                model.mileage.googleCallsInfo && model.mileage.googleCallsInfo.notesForGoogle && isToGoogleNotesDisplay">
                                    <div class="float-left text-left">
                                        <small class="color-red">
                                            {{ model.mileage.googleCallsInfo.notesForGoogle }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 no-padding-right-left">
                            <div class="d-flex justify-content-start">
                                <span class="padding-left-20" *ngIf="this.fnIsMileageGoogleExpense()">
                                    <button type="button" class="btn bg-mustard border-warning fw-600 f-12 cursor-pointer" (click)="onCalculateMileageClick()"
                                     [ngClass]="{ disabled: model.disabledCalculateBtn
                                         || (model.isEditMode && model.baseCategoryId == 3 && !model.isExpenseCategoryActive)}">
                                         {{ "text_calculate" | translate }}
                                    </button>
                                </span>
                                <span class="padding-left-0 align-middle text-logo-blue f-12 top-20px" *ngIf="fnIsMileageGoogleExpense()"
                                style="position: absolute; top: 37px !important;left: 12%;"> 
                                    <input type="checkbox" [(ngModel)]="this.model.mileage.hasReturnJourney" (input)="onReturnJourneyInput()">
                                    {{ 'return_journey' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row margin-top-20">
                        <div class="col-lg-5 padding-left-0">
                            <div class="input-group">
                                <input type="text" class="form-control has-value" threeDigitBeforeAndTwoDigitAfterDecimalNumber 
                                [(ngModel)]="model.totalDistanceOfMultiple" (input)="onTotalDistanceOfMultipleInput()" (paste)="$event.preventDefault()"
                                [ngClass]="{ 'has-value': model.totalDistanceOfMultiple, 'color-light-grey-disabledType pointer-events-none':fnIsMileageGoogleExpense()}">
                                <label for="ExpenseCategory"> {{ "text_distance" | translate }} ({{this.model.mileage.mileageUOM == mileageUOMEnum.Kilometre ? "km" : "m"}})<span>*</span>
                                </label>
                                <span class="padding-left-0 f-12 fw-700" [innerHTML]="sanitizerHtml(model)"></span>
                            </div>
                        </div>
                        <div class="col-lg-5 no-padding-right-left" *ngIf="isCarbonFootprintReporting">                        
                            <div class="input-group">
                                <input type="text" class="form-control has-value" threeDigitBeforeAndTwoDigitAfterDecimalNumber 
                                [(ngModel)]="model.totalCarbonFootprint" (input)="onTotalDistanceOfMultipleInput()" (paste)="$event.preventDefault()"
                                [ngClass]="{ 'has-value': model.totalCarbonFootprint,
                                    'color-light-grey-disabledType pointer-events-none':fnIsMileageGoogleExpense() || fnIsManualMileageExpense()}">
                                <label for="ExpenseCategory"> {{ "carbon_emission" | translate }} ({{ carbonEmissionText }})<span>*</span>
                                </label>                               
                            </div>
                        </div>
                        <div class="col-lg-5 no-padding-right-left" *ngIf="this.fnIsManualMileageExpense()">
                            <span class="padding-left-0 padding-top-10 d-block">
                                <button type="button" class="btn bg-mustard border-warning fw-600 f-12" (click)="onCalculateMileageClick()"
                                     [ngClass]="{ disabled: model.disabledCalculateBtn
                                         || (model.isEditMode && model.baseCategoryId == 3 && !model.isExpenseCategoryActive)}">
                                         {{ "text_calculate" | translate }}
                                    </button>
                            </span>
                        </div>
                    </div>


                    <div *ngFor="let mileageDateDetails of fnGetActiveMileageMultipleDateDetails() ; let last = last">

                        <div class="row margin-top-20">
                            <div class="col-lg-5 padding-left-0 ">
                                <div class="input-group">
                                    <input type="text" class="form-control color-light-grey-disabledType pointer-events-none" 
                                    [value]="fnGetRateText(mileageDateDetails)" 
                                    [ngClass]="{ 'has-value': fnGetRateText(mileageDateDetails)}" readonly>
                                    <label for="ExpenseCategory">{{'text_rate' | translate }}</label>
                                </div>
                            </div>
                            <div class="col-lg-5 no-padding-right-left ">
                                <div class="input-group">
                                    <input type="text" class="form-control color-light-grey-disabledType pointer-events-none"
                                     [value]="fnGetAmountText(mileageDateDetails)" 
                                    [ngClass]="{ 'has-value': fnGetAmountText(mileageDateDetails)}" readonly>
                                    <label for="ExpenseCategory">{{'text_amount' | translate }}</label>
                                </div>
                            </div>
                            <div class="col-lg-2 padding-right-0" (click)="fnOpenCreateMileageTagPanel(mileageDateDetails)">
                                <a class="create-expense-tag d-inlne-block ml-1 position-relative top-1px f-12">
                                    <span>
                                        <i class="fas fa-tag text-charcoal-black" aria-hidden="true"></i>
                                    </span> 
                                    {{'text_tag' | translate }} ({{mileageDateDetails.tags ? mileageDateDetails.tags.length:0}})
                                </a>
                            </div>
                        </div>

                        <div class="row margin-top-20">
                            <div class="col-lg-5 padding-left-0" *ngIf="this.fnIsMileageGoogleExpense() && model?.isDetourEnabled">
                                <div class="input-group" >
                                    <input type="text" class="form-control" readonly [value]="fnGetDetour(mileageDateDetails.detour)"  
                                    [ngClass]="{ 'has-value': fnGetDetour(mileageDateDetails.detour)}" (click)="fnOpenSelectDetourPanel(mileageDateDetails)">
                                    <label for="ExpenseCategory">
                                        {{ 'detour' | translate }} ({{this.model.mileage.mileageUOM == mileageUOMEnum.Kilometre ? "km" : "m"}})
                                    </label>
                                    <span class="padding-left-0 align-middle text-logo-blue f-12 top-20px" *ngIf="!mileageDateDetails.detour">
                                        <a class="text-underline" (click)="fnOpenSelectDetourPanel(mileageDateDetails)">
                                            <i class="far fa-route padding-right-5"></i>
                                            {{'text_add_detour' | translate }}
                                        </a> 
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-5 no-padding-right-left" 
                            *ngIf="model.mileage.isPassengerLoadPurchased && model.mileage.mileageAllowance?.length>0">
                                <div class="input-group">
                                    <input type="text" class="form-control" readonly [value]="fnSetAllowanceText()"
                                    [ngClass]="{ 'has-value': fnSetAllowanceText(), 'no-padding-right-left':this.fnIsMileageGoogleExpense()}"
                                     (click)="fnOpenAddPassengerLoadPanel()">
                                    <label for="ExpenseCategory">{{'add_passenger_load' | translate }}</label>
                                </div>
                            </div>
                        </div>

                        <div class="row margin-top-20">
                            <div class="col-lg-12 no-padding-right-left ">
                                <div class="input-group">
                                    <input type="text" class="form-control" [(ngModel)]="model.notes" 
                                    [ngClass]="{ 'has-value': model.notes}" maxlength="200">
                                    <label for="ExpenseCategory">{{'text_notes' | translate }}</label>
                                    <p class="text-right">
                                        <small>{{model.notes ? model.notes.length : "0"}} / 200</small>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                </div>
                <div id="nav-multipleLeg" class="tab-pane fade" [ngClass]="{ 'show active': activeTab == 'nav-multipleLeg' }">
                    <div class="row padding-bottom-10 padding-top-0">
                        <div class="col-md-12 no-padding-right-left margin-top-0 position-relative">
                            
                           <div class="padding-top-50 padding-bottom-30" >
                                <span class="d-block text-center">
                                    {{ 'legs_added' | translate }}: {{this.model.mileage.sourceDestinationInformation ? this.model.mileage.sourceDestinationInformation.length : "0"}} / 12
                                </span>
                                 <span class="d-block text-center mt-2">
                                    <button type="button" class="btn btn-primary bg-mustard f-13 text-charcoal-black fw-600" 
                                    (click)="fnViewMultipleLegPanel()">
                                        <span *ngIf="this.model.mileage.sourceDestinationInformation 
                                        && this.model.mileage.sourceDestinationInformation.length >0">
                                        {{ 'view_journey' | translate }}
                                        </span>
                                        <span *ngIf="this.model.mileage.sourceDestinationInformation
                                         && this.model.mileage.sourceDestinationInformation.length == 0">
                                         {{ 'add_journey' | translate }}
                                        </span>
                                    </button> 
                                </span>
                                        
                           </div>

                        </div>
                    </div>
                </div>
            </div>
            <p class="position-absolute right-0 f-13 top-25px text-logo-blue cursor-pointer text-right w-20pc"
             *ngIf="this.fnIsMileageGoogleExpense()" (click)="fnViewMileageMapAndCalculations()" 
             [ngClass]="{ disabled: !(this.model.mileageMultipleDateDetails && this.model.mileageMultipleDateDetails.length> 0)}">
                <span class="padding-right-3">
                    <i class="fas fa-map-marker-alt"></i>
                </span>
                <span> {{ 'view_map_calculation' | translate }}</span>
            </p>
        </div>
    </div>
</div>

<app-alert-panel *ngIf="showDebtourNotActivatedAlertPanel" [data]="debtourNotActivatedAlertModel"
    (callbackEvent)="fnCloseDebtourNotActivatedAlertPanel($event)">
</app-alert-panel>

<create-detour-panel *ngIf="showSelectDetourPanel" [inputData]="selectDetourInputModel"
    (closePanelEvent)="fnCloseSelectDetourPanel($event)" (selectItemEvent)="fnSelectDetourPanel($event)">
</create-detour-panel>

<create-tag-panel *ngIf="showCreateMileageTagPanel" [inputData]="createMileageTagInputModel"
    (closePanelEvent)="fnCloseCreateMileageTagPanel($event)" (selectItemEvent)="fnSelectCreateMileageTag($event)">
</create-tag-panel>

<select-recent-journeys-panel *ngIf="showSelectRecentJourneyPanel" [inputData]="selectRecentJourneyInputModel"
    (closePanelEvent)="fnCloseSelectRecentJourneyPanel($event)" (selectItemEvent)="fnSelectRecentJourney($event)">
</select-recent-journeys-panel>


<app-alert-panel *ngIf="showSwitchMultipleLegAlertPanel" [data]="switchMultipleLegAlertModel"
    (callbackEvent)="fnCloseSwitchMultipleLegAlertPanel($event)">
</app-alert-panel>
<office-address-panel *ngIf="isOfficeAddressPanel" (closePanelEvent)="closeOfficeAddPanel($event)">
</office-address-panel>
