<div class="input-group margin-top-20">
    
        <input id="{{ 'loc' + fieldKey }}" autocomplete="off" type="text" class="form-control padding-right-30"
            [formControl]="formControl" [formlyAttributes]="field" (input)="onTextInput()" (click)="fnOnInputClick()"
            (keyup)="fnSearchAddress($event.target.value)" (keypress)="fnKeypress($event.target.value)"
             (blur)="fnCloseGoogleDropdown()" (keydown)="fnKeyDown()"
            [ngClass]="{'has-value': formControl.value,'disabled color-light-grey-disabledType': to.disabled }">

        <label [ngClass]="{'disabled color-light-grey-disabledType': to.disabled }" for="ExpenseCategory">
            {{field.templateOptions.label}}{{gm_lable}}<span *ngIf="to.required">*</span>
        </label>

        <span class="icons" *ngIf="!isToDelIconDisplay" (click)="fnSearchPlacePrediction()">
            <a [ngClass]="{'disabled color-light-grey-disabledType': to.disabled }">
                <i class="fas fa-search" aria-hidden="true"></i>
            </a>
        </span>
        <span class="icons" *ngIf="isToDelIconDisplay && !to.disabled" (click)="fnDeleteText()">
            <a>
                <i class="fas fa-times-circle color-light-grey" aria-hidden="true"></i>
            </a>
        </span>

        <div class="dropdown-menu dropdown-menu-list" [ngClass]="{ active: isHomeOfcDropdownVisible }">
            <ul>
                <li (click)="fnSetAddressFromTriangulation(model.userHomeAddressInfo)">
                    <p>
                        <strong>{{'home' | translate }}</strong>
                    </p>
                    <p>
                        {{model?.userHomeAddressInfo?.address}}
                    </p>
                </li>
                <li (click)="fnSetAddressFromTriangulation(model.userOfficeAddressInfo)">
                    <p>
                        <strong>{{'office' | translate }}</strong>
                    </p>
                    <p>
                        {{model?.userOfficeAddressInfo?.address}}
                    </p>
                </li>
                <li *ngIf="model.isOtherOfficeAvailable" (click)="isOfficeAddressPanel = true;" style="margin-top: 5px;">
                    <p>
                        <strong>{{'office_address_other' | translate }}</strong>
                    </p>
                </li>
            </ul>
        </div>
        <div class="dropdown-menu dropdown-menu-list" [ngClass]="{ active: isGoogleDropdownVisible }">
            <ul *ngFor="let item of googleDynamicAddressList">
                <li (click)="fnSetAddress(item)"> {{item.description}} </li>
            </ul>
        </div>

        <div class="dropdown-menu dropdown-menu-list" [ngClass]="{ active: isGoogleAddressNotFound }">
            <ul>
                <li>{{'no_match_found' | translate }}</li>
            </ul>
        </div>

        <div *ngIf="formControl.errors && formControl.errors.required && isFormSubmitted" class="alert-danger">
            <span *ngIf="!formControl.value || !formControl.value.trim()">
                <b>{{ "label_Required" | translate }}</b>
            </span>
        </div>
        <div class="float-left w-100pc f-15" 
        *ngIf="model.googleCallsInfo && model.googleCallsInfo.notesForGoogle && isGoogleNotesDisplay">
            <div class="float-left text-left">
                <small class="color-red">
                    {{ model.googleCallsInfo.notesForGoogle }}
                </small>
            </div>
        </div>
</div>
<office-address-panel *ngIf="isOfficeAddressPanel" (closePanelEvent)="closeOfficeAddPanel($event)">
</office-address-panel>
