<!-- <div [ngClass]="mainClass" style="height: 35px !important;" class="padding-left-10">
    <input id="{{ 'cbx' + fieldKey }}" type="checkbox" class="custom-control-input" [checked]="formControl.value"
        [formControl]="formControl" (input)="onValueChange()" [disabled]="field.templateOptions.disabled">

    <label [ngClass]="{'bigToggle': enableBigToggle}" class="custom-control-label cursor-pointer f-20 fw-600 lH-25 padding-top-5 padding-left-15 position-relative" for="{{ 'cbx' + fieldKey }}"
        *ngIf="formControl.value">
        {{field.templateOptions.labelActive}}
    </label>
    <label [ngClass]="{'bigToggle': enableBigToggle}" class="custom-control-label cursor-pointer f-20 fw-600 lH-25 padding-top-5 padding-left-15  position-relative" for="{{ 'cbx' + fieldKey }}"
        *ngIf="!formControl.value">
        {{field.templateOptions.labelDeactive}}
    </label>
</div> -->

<div class="d-flex px-3 pt-3 pb-1">
    <input id="{{ 'cbx' + fieldKey }}" type="checkbox" switch="bool" [checked]="formControl.value"
    [formControl]="formControl" (input)="onValueChange()" [disabled]="field.templateOptions.disabled">
    <label for="{{ 'cbx' + fieldKey }}" *ngIf="formControl.value" class="mb-0 cursor-pointer">
        <span class="ml-5 eod-text-secondary fw-600 f-18 text-nowrap">{{field.templateOptions.labelActive}}</span>
    </label>
    <label for="{{ 'cbx' + fieldKey }}" *ngIf="!formControl.value" class="mb-0 cursor-pointer">
        <span class="ml-5 eod-text-secondary fw-600 f-18 text-nowrap">{{field.templateOptions.labelDeactive}}</span>
    </label> 
</div>