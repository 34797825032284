<div class="overLayDiv active"></div>
<!-- Email Receipts Info Panel -->
<div *ngIf="!(receiptExpenseList && receiptExpenseList.length>0)" id="rightSideBar">
    <div class="rightSidePanel active">
        <header>
            <div class="row">
                <div class="col-lg-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-lg-8 padding-left-0">
                            <h2 class="f-18">{{"reciepts_email" | translate}}</h2>
                        </div>
                        <div
                            class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">
                                <li (click)="fnClosePanel()">
                                    <button
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                        {{ 'label_close' | translate }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 margin-top-10 no-padding-right-left">
                <div class="block padding-top-50 padding-bottom-50">
                    <div class="row">
                        <div class="col-lg-7 padding-left-0"> <span><img
                                    src="../../assets/svg-icons/receipt-create-expense.svg" alt=""></span></div>
                        <div class="col-lg-5 padding-right-0 bg-grey-1 padding-10 rounded">
                            <div>
                                <ul class="margin-top-35">
                                    <li>
                                        <p class="position-relative padding-left-20 lH-normal f-13"><span
                                                class="position-absolute left-0px"><i
                                                    class="fas fa-circle f-10 text-logo-blue"></i></span>{{'send_your_receipt_on_email_to'
                                            | translate:param}}:
                                            <a class="">receipts@expenseondemand.com</a>
                                        </p>
                                    </li>

                                    <li class="margin-top-10">
                                        <p class="position-relative padding-left-20 lH-normal f-13"><span
                                                class="position-absolute left-0px"><i
                                                    class="fas fa-circle f-10 text-logo-blue"></i></span>{{'view_and_review_all_receips_in_incomplete_expenses'
                                            | translate:param}}
                                        </p>
                                    </li>

                                    <li class="margin-top-10">
                                        <p class="position-relative padding-left-20 lH-normal f-13"><span
                                                class="position-absolute left-0px"><i
                                                    class="fas fa-circle f-10 text-logo-blue"></i></span>{{'attach_add_to_expenses_and_submit_note1'
                                            | translate:param}}
                                            <span class="d-block">
                                                {{'attach_add_to_expenses_and_submit_note2' | translate:param}}</span>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Expense List Panel -->
<div *ngIf="receiptExpenseList && receiptExpenseList.length>0" id="rightSideBar">
    <div class="rightSidePanel w-60pc active">
        <header>
            <div class="row">
                <div class="col-lg-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-lg-8 padding-left-0">
                            <h2 class="f-18">{{'saved_and_emailed_receipts' | translate:param}}</h2>
                        </div>
                        <div
                            class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">
                                <li (click)="fnSavePanel()" class="padding-left-0">
                                    <button class="btn btn-primary height-38px margin-right-15"
                                    [ngClass]="{'disabled-state':!isSaveEnabled}">
                                        {{'label_save' | translate:param}}</button>
                                </li>
                                <li (click)="fnClosePanel()" class="padding-right-0">
                                    <button
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                        {{'label_exit' | translate:param}}</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div class="row">
            <div class="col-lg-12  margin-top-10 no-padding-right-left">
                <div class="block padding-top-30 ">
                    <div class="row">
                        <div class="col-lg-6 padding-left-0">
                            <div class="input-group">
                                <ng-select class="solo-dropdown filterDropdown" [items]="gridFilterItems"
                                    bindLabel="name" bindValue="id"
                                    placeholder="{{ 'label_filter' | translate: param }}:" [clearable]="false"
                                    [selectOnTab]="true" [(ngModel)]="statusValue" (change)="fnFilterChanged()"
                                    notFoundText="{{ 'no_data_available' | translate: param }}">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clear"></div>
                <div class="margin-top-10 scrollbar-visible overflow-auto heightCalc-12">

                    <div class="block" *ngIf="(filteredReceiptExpenseList).length === 0">
                        <p class="text-center noDataAvailable disabled opacity-60">
                            {{ "no_data_available" | translate: param }}</p>
                    </div>

                    <div *ngFor="let item of filteredReceiptExpenseList" class="block">
                        <!-- Expense Details Section - Start -->
                        <table class="table border-bottom">
                            <thead>
                                <tr>
                                    <th width="20%">
                                        <input class="checkBoxItem" name="chkBxEachItme" type="checkbox"
                                            (change)="fnSelectExpenseId(item.expenseId)"
                                            [checked]="isExpenseChecked(item.expenseId)" />
                                        {{'text_category' | translate:param}}
                                    </th>
                                    <th width="17%">{{'ExpenseDate' | translate:param}}</th>
                                    <th class="text-right padding-right-30" width="23%">Amount ({{localOrgCurrency}})
                                    </th>
                                    <th width="25%">{{'text_supplier' | translate:param}}</th>
                                    <th width="25%">{{'text_notes' | translate:param}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-truncate" width="20%">
                                        {{getOrgCategoryName(item.orgExpenseCategoryId)}}</td>
                                    <td width="25%">{{item.expenseDate | date: 'dd-MMM-yyyy'}}</td>
                                    <td class="text-right padding-right-30">{{ item.amount | number: '.2-2' | noComma }}
                                    </td>
                                    <td class="text-truncate">{{item.supplier}}</td>
                                    <td class="text-left text-truncate">{{item.notes}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- Expense Details Section - End -->

                        <!-- Attachment Section - Start -->
                        <div *ngIf="item.hasReceipt || item.hasTags || item.hasVoiceMemo"
                            class="margin-left-10 w-100pc text-center">

                            <span *ngIf="item.hasReceipt" (click)="fnShowReceiptsPanel(item.receiptInfo)"
                                class="margin-right-15 padding-top-10">
                                <button class="bg-transparent cursor-pointer">
                                    <span class="d-block lH-normal">
                                        <i class="fas fa-file-alt f-20 text-logo-blue" aria-hidden="true"></i>
                                    </span>
                                    <span class="f-12">
                                        {{'receipts' | translate:param}}
                                    </span>
                                </button>
                                <span class="top--17px badge badge-pill badge-danger badge-pill-style right-20">
                                    {{item.receiptInfo ? item.receiptInfo.length : 0}}
                                </span>
                            </span>

                            <span *ngIf="item.hasTags" (click)="fnShowViewTagPanel(item.tagList)"
                                class="margin-right-15 padding-top-10">
                                <button class="bg-transparent cursor-pointer">
                                    <span class="d-block lH-normal">
                                        <i class="fas fa-tag f-18 text-logo-blue" aria-hidden="true"></i>
                                    </span>
                                    <span class="f-12">
                                        {{'view_tag' | translate:param}}
                                    </span>
                                </button>
                                <span class="top--17px badge badge-pill badge-danger badge-pill-style right-25">
                                    {{item.tagList ? item.tagList.length : 0}}
                                </span>
                            </span>

                            <span *ngIf="item.hasVoiceMemo"
                                (click)="fnShowViewVoiceMemoPanel(item.memoInfo.name, item.memoInfo.url)"
                                class="margin-right-15 padding-top-10">
                                <button class="bg-transparent cursor-pointer">
                                    <span class="d-block lH-normal">
                                        <i class="fas fa-microphone f-18 text-logo-blue" aria-hidden="true"></i>
                                    </span>
                                    <span class="f-12">
                                        {{'view_memo' | translate:param}}
                                    </span>
                                </button>
                            </span>

                        </div>
                        <!-- Attachment Section - End -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<view-voice-memo-panel *ngIf="showViewVoiceMemoPanel" [inputData]="viewVoiceMemoInputModel"
    (closePanelEvent)="showViewVoiceMemoPanel = false"></view-voice-memo-panel>

<view-tag-panel *ngIf="showViewTagPanel" [inputData]="viewTagList" (closePanelEvent)="showViewTagPanel = false">
</view-tag-panel>

<app-view-receipt #viewReceiptComponent [receiptInfo]="viewReceiptsList" [receiptCount]="receiptCount">
</app-view-receipt>

<app-alert-panel *ngIf="showMoreThanOneRecordAlert" [data]="moreThanOneRecordAlertModel"
    (callbackEvent)="fnThanOneRecordAlertPanelReceiver($event)"></app-alert-panel>

    
<app-alert-panel *ngIf="showReceiptLimitAlertPopup" [data]="receiptLimitAlertPopupModel"
(callbackEvent)="fnReceiptLimitPopupReceiver($event)">
</app-alert-panel>