<div
  class="container-fluid top-bar w-100pc"
  id=""
  [ngStyle]="{
    'pointer-events': isOrgBasicFunctionTrialEndsAlert ? 'none' : 'inherit'
  }"
  style="position: relative; height: 60px">
  <div>
    <nav class="navbar navbar-expand-lg" style="height: 60px">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="dark-blue-text"><i class="fas fa-bars fa-1x"></i></span>
      </button>
      <div
        class="collapse navbar-collapse d-flex justify-content-between"
        id="navbarSupportedContent">
        <div
          *ngIf="claims.orgRoleId != 3"
          class="navbar-brand-box"
          style="background: white; min-width: 190px; height: 60px">
          <a
            href="javascript: void(0)"
            class="logo logo-light d-flex justify-content-center"
            style="width: 160px; height: 55px; padding: 3px">
            <img [src]="imageSource" alt="" height="23" width="170px" />
          </a>
        </div>

        <ul class="navbar-nav">
          <!-- Dashboard Menu -->
          <li class="nav-item" *ngIf="isFinanceManager">
            <ul class="subMenu nav nav-tabs" aria-labelledby="navbarDropdown6">
              <li>
                <a
                  [routerLink]="['/dashboard/overview']"
                  [routerLinkActive]="['active']"
                  class="padding-left-0"
                  >{{ "cart_overview" | translate : param }}</a
                >
              </li>
              <li *ngIf="isCardSetupComplete && isCardAccess">
                <a
                  [routerLink]="['/dashboard/carddashboard']"
                  [routerLinkActive]="['active']"
                  >{{ "payment_type_credit_card" | translate : param }}</a
                >
              </li>
              <li
                *ngIf="
                  (this.claims.isAdvancesFunctionPurchasedOrTrial ||
                    advanceBalanceOutstanding) &&
                  isAdvancesAccess
                ">
                <a
                  [routerLink]="['/dashboard/advances']"
                  [routerLinkActive]="['active']"
                  [class.active]="routeIsActive('/dashboard/advance')">
                  {{ "label_Advance" | translate : param }}</a
                >
              </li>
              <li
                *ngIf="
                  this.claims.isAPAutomationFunctionPurchasedOrTrial &&
                  isApAutomationAccess
                ">
                <a
                  [routerLink]="['/dashboard/vendors']"
                  [routerLinkActive]="['active']"
                  [class.active]="routeIsActive('/dashboard/vendors')">
                  {{ "label_vendor_dashborad" | translate : param }}</a
                >
              </li>
              <li
                *ngIf="
                  isCarbonFootprintPurchasedOrTrial && isCarbonFootprintAccess
                ">
                <a
                  [routerLink]="['/dashboard/carbonFootprint']"
                  [routerLinkActive]="['active']"
                  [class.active]="routeIsActive('/dashboard/carbonFootprint')">
                  {{ "carbon_footprint" | translate : param }}</a
                >
              </li>
            </ul>
          </li>

          <li *ngIf="claims.orgRoleId == 3">
            <button
              type="button"
              class="btn btn-sm px-3 font-size-16 border-0 eod-text-warning vertinav-toggle header-item waves-effect"
              id="vertical-menu-btn"
              (click)="toggleMobileMenu($event)">
              <i class="fa fa-fw fa-bars f-24"></i>
            </button>
          </li>

          <!-- Claims Menu -->
          <li *ngIf="isClaimOnWebAllowed" class="nav-item">
            <a
              class="nav-link"
              id="navbarClaimant"
              role="button"
              data-bs-toggle="collapse"
              [routerLink]="
                isDelegateFunctionPurchasedOrTrial && isDelegate
                  ? ['/claimant/delegatorsexpense']
                  : ['/claimant/expenselist']
              "
              [class.active]="
                routeIsActive('/claimant') ||
                routeIsActive('/orgsetting/claimantfcdashboard')
              ">
              {{ "claim" | translate : param }}
            </a>
            <ul
              class="subMenu nav nav-tabs"
              aria-labelledby="navbarClaimant"
              [class.active]="
                routeIsActive('/claimant') ||
                routeIsActive('/orgsetting/claimantfcdashboard')
              ">
              <li>
                <a
                  [routerLink]="
                    isDelegateFunctionPurchasedOrTrial && isDelegate
                      ? ['/claimant/delegatorsexpense']
                      : ['/claimant/expenselist']
                  "
                  [routerLinkActive]="['active']"
                  class="padding-left-0">
                  <span *ngIf="userType == 0">{{
                    "expense" | translate : param
                  }}</span>
                  <span *ngIf="userType == 1">{{
                    "invoice" | translate : param
                  }}</span>
                </a>
              </li>
              <li
                *ngIf="
                  isDelegateFunctionPurchasedOrTrial &&
                  isDelegator &&
                  userType == 0
                ">
                <a
                  [routerLink]="['/claimant/delegate']"
                  [routerLinkActive]="['active']"
                  class="padding-left-0"
                  [class.active]="routeIsActive('/claimant/delegate')"
                  >{{ "delegate" | translate : param }}</a
                >
              </li>

              <li *ngIf="this.claims.IsCashAdvanceEnabled && userType == 0">
                <a
                  [routerLink]="['/claimant/cashadvancelist']"
                  [routerLinkActive]="['active']"
                  class="padding-left-0"
                  [class.active]="routeIsActive('/claimant/cashadvancelist')"
                  >{{ "cash_advance_title" | translate : param }}</a
                >
              </li>
              <li
                *ngIf="this.claims.IsCreditCardAdvanceEnabled && userType == 0">
                <a
                  [routerLink]="['/claimant/ccadvancelist']"
                  [routerLinkActive]="['active']"
                  class="padding-left-0"
                  [class.active]="routeIsActive('/claimant/ccadvancelist')"
                  >{{
                    "cash_advance_on_credit_card_title" | translate : param
                  }}</a
                >
              </li>
              <li
                *ngIf="
                  this.claims.isNRPackagePurchasedOrTrial &&
                  this.claims.nrfcStatus == 1 &&
                  userType == 0
                ">
                <a
                  [routerLink]="['/claimant/claimantnrlist']"
                  [routerLinkActive]="['active']"
                  [class.active]="routeIsActive('/claimant/claimantnrlist')">
                  {{ "non_reimbursable_fuel" | translate : param }}
                </a>
              </li>
              <li
                *ngIf="
                  this.claims.isFCPackagePurchasedOrTrial &&
                  this.claims.nrfcStatus == 2 &&
                  userType == 0
                ">
                <a
                  [routerLink]="['/claimant/claimantfclist']"
                  [routerLinkActive]="['active']"
                  [class.active]="routeIsActive('/claimant/claimantfclist')">
                  {{ "fuel_card" | translate : param }}
                </a>
              </li>
              <li
                *ngIf="
                  this.claims.isNRPackagePurchasedOrTrial &&
                  this.claims.nrfcStatus == 1 &&
                  userType == 0
                ">
                <a
                  [routerLink]="['/claimant/claimantdashboard']"
                  [routerLinkActive]="['active']"
                  [class.active]="
                    routeIsActive('/claimant/claimantdashboard') ||
                    routeIsActive('/claimant/claimantdashboarddetail')
                  ">
                  {{ "nr_dashboard" | translate : param }}
                </a>
              </li>

              <li
                *ngIf="
                  this.claims.isFCPackagePurchasedOrTrial &&
                  this.claims.nrfcStatus == 2 &&
                  userType == 0
                ">
                <a
                  [routerLink]="['/orgsetting/claimantfcdashboard']"
                  [routerLinkActive]="['active']"
                  [class.active]="
                    routeIsActive('/orgsetting/claimantfcdashboard')
                  ">
                  {{ "fc_report" | translate : param }}
                </a>
              </li>
              <li *ngIf="userType != userTypeEnum.Claimant">
                <a
                  [routerLink]="['/claimant/mymileagerate']"
                  [routerLinkActive]="['active']"
                  class="padding-left-0">
                  {{ "vehicle_mangt_msg44" | translate : param }}
                </a>
              </li>
            </ul>
          </li>
          <li *ngIf="userType == 1 && kycStatus != 0" class="nav-item">
            <a
              class="nav-link"
              id="navbarClaimant"
              role="button"
              data-bs-toggle="collapse"
              [routerLink]="['/vendorSetup/claimantkyc']"
              [class.active]="routeIsActive('/vendorSetup')">
              {{ "kyc_documents" | translate : param }}
            </a>
            <ul
              class="subMenu nav nav-tabs"
              aria-labelledby="navbarClaimant"
              [class.active]="routeIsActive('/vendorSetup')">
              <li>
                <a
                  [routerLink]="['/vendorSetup/claimantkyc']"
                  [routerLinkActive]="['active']"
                  class="padding-left-0">
                  <span> {{ "kyc_documents" | translate : param }}</span>
                </a>
              </li>
            </ul>
          </li>
          <!-- Approver Menu -->
          <li
            class="nav-item"
            *ngIf="
              isClaimOnWebAllowed &&
              (claims?.orgRoleId == 2 || claims?.orgRoleId == 3)
            ">
            <a
              class="nav-link"
              id="navbarDropdown1"
              role="button"
              data-bs-toggle="collapse"
              [routerLink]="['/approver/appclaimant', 1]"
              [class.active]="routeIsActive('/approver')"
              >{{ "approval_route" | translate : param }}</a
            >
            <ul
              class="subMenu nav nav-tabs"
              aria-labelledby="navbarDropdown1"
              [class.active]="routeIsActive('/approver')">
              <li>
                <a
                  [routerLink]="['/approver/appclaimant', 1]"
                  [class.active]="
                    routeIsActive('/approver/appclaimant/1') ||
                    routeIsActive('/approver/appclaimantexpenses/1')
                  ">
                  {{ "expenses" | translate : param
                  }}<span
                    class="badge badge-pill badge-danger badge-pill-style"
                    *ngIf="this.claims.pendingExpenseCountApprover > 0">
                    {{ this.claims.pendingExpenseCountApprover }}
                  </span>
                </a>
              </li>
              <li *ngIf="this.claims.isNRPackagePurchasedOrTrial">
                <a
                  [routerLink]="['/approver/appnrreceipts']"
                  [routerLinkActive]="['active']">
                  {{ "non_reimbursable_fuel" | translate : param
                  }}<span
                    class="badge badge-pill badge-danger badge-pill-style"
                    *ngIf="this.claims.pendingCountNRApprover > 0">
                    {{ this.claims.pendingCountNRApprover }}
                  </span>
                </a>
              </li>
              <li
                class="padding-left-20 position-relative"
                *ngIf="this.claims.isFCPackagePurchasedOrTrial">
                <a
                  [routerLink]="['/approver/appfcreceipts']"
                  [routerLinkActive]="['active']"
                  class="padding-left-0">
                  {{ "fuel_card" | translate : param
                  }}<span
                    class="badge badge-pill badge-danger badge-pill-style"
                    *ngIf="this.claims.pendingCountFCApprover > 0">
                    {{ this.claims.pendingCountFCApprover }}
                  </span>
                </a>
              </li>
              <li
                class="padding-left-20 position-relative"
                *ngIf="this.claims.isPreApprovalPurchased && this.claims.PreApprovalEnabled">
                <a
                  [routerLink]="['/approver/papproval']"
                  [routerLinkActive]="['active']"
                  class="padding-left-0">
                  {{ "pre_approval" | translate : param
                  }}<span
                    class="badge badge-pill badge-danger badge-pill-style"
                    *ngIf="this.claims.pendingCountPreApproval > 0">
                    {{ this.claims.pendingCountPreApproval }}
                  </span>
                </a>
              </li>
              <li
                class="padding-left-20 position-relative"
                *ngIf="this.claims.isAdvancesFunctionPurchasedOrTrial">
                <a
                  [routerLink]="['/approver/advanceclaimantlist']"
                  class="padding-left-0"
                  [class.active]="
                    routeIsActive('/approver/advanceclaimantlist') ||
                    routeIsActive('/approver/advanceitemslist')
                  ">
                  {{ "label_Advance" | translate : param }}
                  <span
                    class="badge badge-pill badge-danger badge-pill-style"
                    *ngIf="this.claims.pendingAdvancesForApproval > 0">
                    {{ this.claims.pendingAdvancesForApproval }}
                  </span>
                </a>
              </li>
              <li
                class="padding-left-20 position-relative"
                *ngIf="this.claims.isAPAutomationFunctionPurchasedOrTrial">
                <a
                  [routerLink]="['/approver/appclaimant', 2]"
                  class="padding-left-0"
                  [class.active]="
                    routeIsActive('/approver/appclaimant/2') ||
                    routeIsActive('/approver/appclaimantexpenses/2')
                  ">
                  {{ "label_invoice" | translate : param }}
                  <span
                    class="badge badge-pill badge-danger badge-pill-style"
                    *ngIf="this.claims.pendingCountInvoiceApproval > 0">
                    {{ this.claims.pendingCountInvoiceApproval }}
                  </span>
                </a>
              </li>
            </ul>
          </li>

          <li
            class="nav-item"
            *ngIf="
              twoStepApproval && isFinanceManager && isFinanceApprovalAccess
            ">
            <a
              class="nav-link"
              id="navbarDropdown1"
              role="button"
              data-bs-toggle="collapse"
              [routerLink]="['/financeapprover/faexpenses', 1]"
              [class.active]="routeIsActive('/financeapprover')"
              >{{ "finance_approval" | translate : param }}</a
            >
            <ul
              class="subMenu nav nav-tabs"
              aria-labelledby="navbarDropdown1"
              [class.active]="routeIsActive('/financeapprover')">
              <li>
                <a
                  [routerLink]="['/financeapprover/faexpenses', 1]"
                  [class.active]="
                    routeIsActive('/financeapprover/faexpenses/1') ||
                    routeIsActive('/financeapprover/faclaimantexpenses/1')
                  ">
                  {{ "expenses" | translate : param }}
                  <span
                    class="badge badge-pill badge-danger badge-pill-style"
                    *ngIf="this.claims.pendingForFinanceApproval > 0">
                    {{ this.claims.pendingForFinanceApproval }}
                  </span>
                </a>
              </li>
              <li
                *ngIf="this.claims.isNRPackagePurchasedOrTrial && isNRFCAccess">
                <a
                  [routerLink]="['/financeapprover/nrreceipts']"
                  [class.active]="
                    routeIsActive('/financeapprover/nrreceipts') ||
                    routeIsActive('/financeapprover/nrreceiptlist')
                  ">
                  {{ "non_reimbursable_fuel" | translate : param
                  }}<span
                    class="badge badge-pill badge-danger badge-pill-style"
                    *ngIf="this.claims.pendingCountNRFinanceApproval > 0">
                    {{ this.claims.pendingCountNRFinanceApproval }}
                  </span>
                </a>
              </li>
              <li
                class="padding-left-20 position-relative"
                *ngIf="this.claims.isFCPackagePurchasedOrTrial && isNRFCAccess">
                <a
                  [routerLink]="['/financeapprover/fcreceipts']"
                  [routerLinkActive]="['active']"
                  [class.active]="
                    routeIsActive('/financeapprover/fcreceipts') ||
                    routeIsActive('/financeapprover/fcreceiptlist')
                  "
                  class="padding-left-0">
                  {{ "fuel_card" | translate : param
                  }}<span
                    class="badge badge-pill badge-danger badge-pill-style"
                    *ngIf="this.claims.pendingCountFCFinanceApproval > 0">
                    {{ this.claims.pendingCountFCFinanceApproval }}
                  </span>
                </a>
              </li>
              <li
                *ngIf="
                  this.claims.isAdvancesFunctionPurchasedOrTrial &&
                  isAdvancesAccess
                ">
                <a
                  [routerLink]="['/financeapprover/advanceclaimantlist']"
                  [class.active]="
                    routeIsActive('/financeapprover/advanceclaimantlist') ||
                    routeIsActive('/financeapprover/advanceitemslist')
                  ">
                  {{ "label_Advance" | translate : param }}
                  <span
                    class="badge badge-pill badge-danger badge-pill-style"
                    *ngIf="this.claims.pendingAdvanceForFinanceApproval > 0">
                    {{ this.claims.pendingAdvanceForFinanceApproval }}
                  </span>
                </a>
              </li>
              <li
                *ngIf="
                  this.claims.isAPAutomationFunctionPurchasedOrTrial &&
                  isApAutomationAccess
                ">
                <a
                  [routerLink]="['/financeapprover/faexpenses', 2]"
                  [class.active]="
                    routeIsActive('/financeapprover/faexpenses/2') ||
                    routeIsActive('/financeapprover/faclaimantexpenses/2')
                  ">
                  {{ "label_invoice" | translate : param }}
                  <span
                    class="badge badge-pill badge-danger badge-pill-style"
                    *ngIf="this.claims.pendingCountInvoiceFinanceApproval > 0">
                    {{ this.claims.pendingCountInvoiceFinanceApproval }}
                  </span>
                </a>
              </li>
            </ul>
          </li>

          <li
            class="nav-item"
            *ngIf="
              twoStepApproval &&
              isFinanceManager &&
              isPayAccess &&
              isPayStepEnabled
            ">
            <a
              class="nav-link"
              id="navbarDropdown1"
              role="button"
              data-bs-toggle="collapse"
              [routerLink]="['/pay/payexpenses', 1]"
              [class.active]="routeIsActive('/pay')"
              >{{ "pay" | translate : param }}</a
            >
            <ul
              class="subMenu nav nav-tabs"
              aria-labelledby="navbarDropdown1"
              [class.active]="routeIsActive('/pay')">
              <li>
                <a
                  [routerLink]="['/pay/payexpenses', 1]"
                  [class.active]="
                    routeIsActive('/pay/payexpenses/1') ||
                    routeIsActive('/pay/payclaimantexpenses/1')
                  ">
                  {{ "pay_expense" | translate : param }}
                  <span
                    class="badge badge-pill badge-danger badge-pill-style"
                    *ngIf="this.claims.pendingExpensesForPayment > 0">
                    {{ this.claims.pendingExpensesForPayment }}
                  </span>
                </a>
              </li>
              <li
                *ngIf="
                  this.claims.isAdvancesFunctionPurchasedOrTrial &&
                  isAdvancesAccess
                ">
                <a
                  [routerLink]="['/pay/advanceclaimantlist']"
                  [class.active]="
                    routeIsActive('/pay/advanceclaimantlist') ||
                    routeIsActive('/pay/advanceitemslist')
                  ">
                  {{ "label_pay_Advance" | translate : param }}
                  <span
                    class="badge badge-pill badge-danger badge-pill-style"
                    *ngIf="this.claims.pendingAdvancesForPayment > 0">
                    {{ this.claims.pendingAdvancesForPayment }}
                  </span>
                </a>
              </li>
              <li
                *ngIf="
                  this.claims.isAPAutomationFunctionPurchasedOrTrial &&
                  isApAutomationAccess
                ">
                <a
                  [routerLink]="['/pay/payexpenses', 2]"
                  [class.active]="
                    routeIsActive('/pay/payexpenses/2') ||
                    routeIsActive('/pay/payclaimantexpenses/2')
                  ">
                  {{ "label_payinvoice" | translate : param }}
                  <span
                    class="badge badge-pill badge-danger badge-pill-style"
                    *ngIf="this.claims.pendingCountInvoicePay > 0">
                    {{ this.claims.pendingCountInvoicePay }}
                  </span>
                </a>
              </li>
            </ul>
          </li>

          <!-- Cards Menu -->
          <li
            class="nav-item"
            *ngIf="isFinanceManager && isCardSetupComplete && isCardAccess && manageCCOptions!=2">
            <a
              (click)="navigatToImportCardStatement()"
              class="nav-link"
              *ngIf="isCreditCardPackageSubscribed"
              id="navbarDropdown5"
              role="button"
              data-bs-toggle="collapse"
              [routerLink]="['/universalimport/ccuploadfile']"
              [class.active]="isCardMenuActive()"
              >{{ "cards" | translate : param }}</a
            >

            <ul
              *ngIf="isCreditCardPackageSubscribed"
              class="subMenu nav nav-tabs"
              aria-labelledby="navbarDropdown5"
              [class.active]="isCardMenuActive()">
              <li *ngIf="isCreditCardPackageSubscribed">
                <a
                  (click)="navigatToImportCardStatement()"
                  [routerLink]="['/universalimport/ccuploadfile']"
                  [class.active]="activateImportStatementMenu"
                  class="padding-left-0">
                  {{ "cc_dashboard_import_statement" | translate : param }}
                </a>
              </li>
            </ul>
            <ul
              *ngIf="!isCreditCardPackageSubscribed"
              class="subMenu nav nav-tabs"
              aria-labelledby="navbarDropdown5"
              [class.active]="routeIsActive('/card')">
              <li *ngIf="isCreditCardPackageSubscribed">
                <a
                  (click)="navigatToImportCardStatement()"
                  [routerLink]="['/universalimport/ccuploadfile']"
                  [class.active]="activateImportStatementMenu"
                  class="padding-left-0">
                  {{ "cc_dashboard_import_statement" | translate : param }}
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-item" *ngIf="isFinanceManager && isBuildReportAccess">
            <a
              class="nav-link"
              id="navbarDropdown1"
              role="button"
              data-bs-toggle="collapse"
              [routerLink]="['/export/expenseexport']"
              [class.active]="routeIsActive('/export')"
              >{{ "accounting_export" | translate : param }}</a
            >
            <ul
              class="subMenu nav nav-tabs"
              aria-labelledby="navbarDropdown1"
              [class.active]="routeIsActive('/export')">
              <li>
                <a
                  [routerLink]="['/export/expenseexport']"
                  [routerLinkActive]="['active']"
                  class="padding-left-0">
                  {{ "export_report" | translate : param }}<span></span>
                </a>
              </li>
              <li>
                <a
                  [routerLink]="['/export/expenseexportlog']"
                  [routerLinkActive]="['active']"
                  >{{ "label_log" | translate : param }}</a
                >
              </li>
              <li>
                <a
                  [routerLink]="['/orgsetting/multiplecompanies']"
                  [routerLinkActive]="['active']"
                  *ngIf="isAccountingIntegrationPackagePurchasedOrTrial"
                  >{{ "accounting_integration" | translate : param }}</a
                >
                <a
                  (click)="
                    fnNavigateToCart(
                      'false',
                      'export/accountingIntegrationList',
                      'Transfer Data to Sage/Tally/QB/Xero function',
                      '29',
                      '0'
                    )
                  "
                  *ngIf="!isAccountingIntegrationPackagePurchasedOrTrial"
                  >{{ "accounting_integration" | translate : param }}</a
                >
              </li>
              <li>
                <a
                  [routerLink]="['/export/reportwizard']"
                  [class.active]="activateCreateNewReportMenu"
                  *ngIf="isExportReportPackagePurchasedOrTrial"
                  >{{ "create_new_report" | translate : param }}</a
                >
                <a
                  (click)="showCreateReportMarketingPopup()"
                  *ngIf="!isExportReportPackagePurchasedOrTrial"
                  >{{ "create_new_report" | translate : param }}</a
                >
              </li>
              <li>
                <a
                  [routerLink]="['/export/existingreports']"
                  [routerLinkActive]="['active']"
                  >{{ "existing_reports" | translate : param }}</a
                >
              </li>
              <li>
                <a
                  *ngIf="!isRecallExportedExpensesPurchased"
                  (click)="showRecallExportedExpensesMarketingPopup()"
                  >{{ "roll_back_exported_expenses" | translate : param }}</a
                >
                <a
                  *ngIf="isRecallExportedExpensesPurchased"
                  [routerLink]="['/export/exportrollback']"
                  [routerLinkActive]="['active']"
                  >{{ "roll_back_exported_expenses" | translate : param }}</a
                >
              </li>
            </ul>
          </li>
        </ul>
        <ul
          class="navbar-nav d-flex justify-content-center"
          *ngIf="
            isFinanceManager &&
            basicFunctionRemainingTrialDays > 0 &&
            bandId > 0
          ">
          <li
            class="nav-item d-flex align-items-center cursor-pointer"
            (click)="openEssPackPanel()">
            <div class="free-trail">
              <span class="free-trail-text-1"
                >{{ basicFunctionRemainingTrialDays }}<br />{{
                  "days" | translate : param
                }}</span
              >
              <span class="free-trail-text-2"
                >{{ "free_trial" | translate : param }}
                <i class="fas fa-chevron-right ml-2 cursor-pointer"></i>
              </span>
            </div>
          </li>
        </ul>
        <ul class="navbar-nav2">
          <li class="nav-item d-flex align-items-center">
            <a
              class="nav-link"
              (click)="fullscreen()"
              style="
                color: #fdc615 !important;
                font-size: 2.5rem;
                padding: 8px;
              ">
              <i
                [ngClass]="
                  isInFullscreenMode ? 'mdi-fullscreen-exit' : ' mdi-fullscreen'
                "
                class="mdi"></i>
            </a>
          </li>

          <li class="nav-item d-flex align-items-center">
            <span class="vr"></span>
          </li>

          <li class="nav-item position-relative" *ngIf="isPrimaryFM">
            <a
              class="nav-link"
              [routerLink]="['/shoppingcartlist']"
              [class.active]="
                routeIsActive('/shoppingcartlist') ||
                routeIsActive('/shoppingcartreview') ||
                routeIsActive('/stripeAgreement') ||
                routeIsActive('/shoppingcartConfirmed')
              ">
              <span>
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="26px"
                  height="26px"
                  viewBox="0 0 26 25"
                  style="enable-background: new 0 0 26 25"
                  xml:space="preserve">
                  <style type="text/css">
                    .st0 {
                      fill: #f4f4f4;
                    }
                  </style>
                  <g>
                    <path
                      class="st0"
                      d="M25.1,8.5c-0.7-0.3-1.6,0-1.9,0.7c-1.3,2.8-3.1,6.1-3.9,6.9h-7.4c-0.8,0-2.2-0.2-2.5-1.7
            c0-0.1-1.5-7.6-1.9-9.7c-0.4-2.2-1.9-3.6-4.1-4H1.7c-0.6,0.2-1,0.8-1,1.4c0,0.8,0.7,1.5,1.5,1.5c1.8,0,2.3,0.7,2.5,1.7
            C5,7.4,6.6,14.9,6.6,15c0.5,2.5,2.6,4.1,5.3,4.1h7.8c0.8,0,2.1,0,6.2-8.6C26,10.3,26,10.2,26,10V9.9C26,9.3,25.7,8.8,25.1,8.5z" />

                    <path
                      class="st0"
                      d="M11,19.7c-1.4,0-2.6,1.1-2.6,2.6c0,1.2,0.8,2.2,1.9,2.5h1.4c1.1-0.3,1.9-1.3,1.9-2.5
            C13.5,20.8,12.4,19.7,11,19.7z" />

                    <path
                      class="st0"
                      d="M20.1,19.7c-1.4,0-2.6,1.1-2.6,2.6c0,1.2,0.8,2.2,1.9,2.5h1.4c1.1-0.3,1.9-1.3,1.9-2.5
            C22.6,20.8,21.5,19.7,20.1,19.7z" />
                  </g>
                </svg>
              </span>
              <span
                class="cartValue"
                [class.cartValue-single]="CartCount.toString().length < 2">
                {{ CartCount }}
              </span>
              <small class="f-13 padding-left-5">
                {{ "cart" | translate : param }}
              </small>
            </a>
          </li>
          <li class="nav-item d-flex align-items-center">
            <span class="vr"></span>
          </li>
          <li class="nav-item">
            <a
              class="nav-link profile-bell position-relative"
              (click)="isNotificationPanelActive = true">
              <img
                [ngClass]="{ ring: notificationsCount > 0 }"
                width="90%"
                src="../../../assets/images/bell-icon.svg"
                alt="" />
              <span *ngIf="notificationsCount" class="eod-badge eod-bg-danger">
                {{ notificationsCount }}
              </span>
            </a>
          </li>
          <li class="nav-item d-flex align-items-center">
            <span class="vr"></span>
          </li>

          <li class="nav-item d-flex align-items-center">
            <a
              class="nav-link nav-avatar"
              (click)="logoutMenuVisible = !logoutMenuVisible">
              <i class="fas fa-user"></i>
            </a>
          </li>
          <!-- 
          <li class="nav-item d-flex align-items-center"><span class="vr"></span></li>



          <li class="nav-item d-flex align-items-center">
            <a class="nav-link"
               style="color: #29b7e9 !important;
            font-size: 2rem;
            padding: 10px;"
               (click)="toggleRightSidebar()">
              <i class="bx bx-cog bx-spin"></i>
            </a>
          </li> -->

          <!-- <li class="nav-item d-flex align-items-center">
            <a class="btn header-item noti-icon right-bar-toggle waves-effect" (click)="logoutMenuVisible = !logoutMenuVisible">
              <i class="bx bx-cog bx-spin"></i>
            </a>
          </li> -->
        </ul>
      </div>
      <div *ngIf="logoutMenuVisible">
        <div
          class="profile-dropdown eod-card active"
          (mouseleave)="logoutMenuVisible = !logoutMenuVisible">
          <div
            class="card-header eod-bg-primary eod-text-white d-flex align-items-center">
            <div class="profile-icon">{{ Initials | uppercase }}</div>
            <div>
              <p class="mb-1">
                <b>{{ FirstName }} {{ LastName }}</b>
              </p>
              <p class="mb-1">{{ Email }}</p>
              <p class="">
                {{ "organisation" | translate : param }} - {{ OrgName }}
              </p>
            </div>
          </div>
          <div class="card-body p-0 row">
            <div class="p-0" [ngClass]="fnGetClass()">
              <div class="card-body py-2">
                <div class="d-flex mb-2">
                  <a
                    href="{{ storeURL.playStore }}"
                    target="_blank"
                    class="mr-1"
                    ><img
                      alt=""
                      src="../../assets/images/android-app-download-button.png"
                      width="90px"
                  /></a>
                  <a href="{{ storeURL.appStore }}" target="_blank"
                    ><img
                      alt=""
                      src="../../assets/images/iphone-app-download-button.png"
                      width="90px"
                  /></a>
                </div>
                <div class="profile-app-qr">
                  <div>
                    <h6>
                      <b>{{ "profile_msg1" | translate : param }}</b>
                    </h6>
                    <img
                      src="../../../assets/images/qr-code.png"
                      alt=""
                      width="120px" />
                  </div>
                  <div class="position-relative">
                    <img
                      class="profile-ph-app"
                      src="../../../assets/images/profile-phone.png"
                      alt="" />
                  </div>
                </div>
              </div>
              <ul class="prfile-list-items">
                <li *ngIf="!IsSocialMediaUser">
                  <a (click)="changePassword()"
                    >{{ "change_password" | translate : param }}
                    <span><i class="fas fa-chevron-right"></i></span
                  ></a>
                </li>
                <li *ngIf="isFinanceManager">
                  <a (click)="supportPage()"
                    >{{ "support_support" | translate : param
                    }}<span><i class="fas fa-chevron-right"></i></span
                  ></a>
                </li>
                <li
                  class="eod-bg-primary"
                  *ngIf="userType != userTypeEnum.Claimant">
                  <a (click)="fnMyMileageRate()"
                    >{{ "vehicle_mangt_msg44" | translate : param }}
                    <span><i class="fas fa-chevron-right"></i></span
                  ></a>
                </li>
                <li>
                  <a (click)="logout()"
                    >{{ "logout" | translate : param
                    }}<span><i class="fas fa-chevron-right"></i></span
                  ></a>
                </li>
              </ul>
            </div>
            <div class="col-4 p-0" *ngIf="isFinanceManager">
              <ul class="prfile-list-items fm-list">
                <li
                  *ngIf="isFinanceManager && myInvoicesVisible"
                  (click)="fnInvoicesClick()">
                  <a
                    >{{ "my_invoices" | translate : param
                    }}<span><i class="fas fa-chevron-right"></i></span>
                  </a>
                </li>

                <li
                  *ngIf="myInvoicesVisible"
                  (click)="paymentMethodActiveTab()">
                  <a
                    >{{ "profile_msg2" | translate : param
                    }}<span><i class="fas fa-chevron-right"></i></span
                  ></a>
                </li>
                <li *ngIf="isPrimaryFM" (click)="changeBillingPlan()">
                  <a
                    >{{ "invoice_discounts" | translate : param
                    }}<span><i class="fas fa-chevron-right"></i></span
                  ></a>
                </li>
                <li *ngIf="isPrimaryFM" (click)="changeBillingPlan()">
                  <a
                    >{{ "billing_settings_cost_control" | translate
                    }}<span><i class="fas fa-chevron-right"></i></span
                  ></a>
                </li>
                <li
                  *ngIf="myInvoicesVisible"
                  (click)="assistedSupportActiveTab()">
                  <a
                    >{{ "implementation_charge" | translate
                    }}<span><i class="fas fa-chevron-right"></i></span
                  ></a>
                </li>
                <li *ngIf="isFinanceManager" (click)="referAndEarnRoute()">
                  <a
                    >{{ "refer_and_earn" | translate : param
                    }}<span><i class="fas fa-chevron-right"></i></span
                  ></a>
                </li>
                <li *ngIf="isPrimaryFM" (click)="unsubscribe()">
                  <a
                    >{{ "unsubscribe_expenseondemand" | translate : param
                    }}<span><i class="fas fa-chevron-right"></i></span
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="profile-overlay"></div>
      </div>
    </nav>
  </div>
  <div class="primary-sub-bar position-relative"></div>
  <div
    *ngIf="
      showLoginMarquee &&
      marqueeText &&
      marqueeText != '' &&
      !activatePricingMenu
    "
    id="marquee">
    <div class="position-relative d-inline-block w-100pc height-25px">
      <marquee
        class="floating-text-marquee-2 text-charcoal-black"
        behavior="scroll"
        direction="left"
        scrollamount="8">
        <span class="">
          <i class="fas fa-exclamation-triangle padding-right-10 color-red"></i>
        </span>
        <span [innerHTML]="sanitizerHtml(marqueeText)"> </span>
      </marquee>
    </div>
  </div>
</div>
<div class="clear"></div>

<div class="overLayDiv active" *ngIf="showExportReportMarketingPopup"></div>

<!-- Data Export -->
<div id="rightSideBar">
  <div
    class="rightSidePanel"
    style="display: block"
    *ngIf="showExportReportMarketingPopup">
    <header>
      <div class="row">
        <div
          class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-right-left">
          <div class="row">
            <div class="col-lg-10 adding-left-0">
              <h2 class="padding-left-60" style="font-size: 1.16rem">
                <span
                  class="color-orange font-weight-bold position-absolute top--10px left-0px">
                  <img
                    src="../../../assets/svg-icons/paid-function-LR.svg"
                    class="svg-64" />
                </span>
                {{
                  "data_transfer_to_other_systems_build_your_own_report"
                    | translate : param
                }}
              </h2>
            </div>
            <div class="col-lg-2 padding-right-0 header-icons text-right">
              <ul class="d-flex justify-content-end w-100pc">
                <li (click)="fnCloseExportReportMarketingPopUp()">
                  <Button
                    class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                    {{ "label_exit" | translate : param }}
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="clear"></div>
    <div
      class="col-md-12 col-sm-12 col-lg-12 col-xl-12 margin-top-10 no-padding-right-left"
      id="liability-report">
      <div class="row">
        <div class="block height-465px padding-top-10">
          <h6
            class="text-uppercase f-13 font-weight-bold padding-bottom-10 border-bottom">
            {{ "transfer_data_and_build_reports" | translate : param }}
          </h6>
          <div class="row margin-top-0">
            <div class="col-lg-7 no-padding-right-left">
              <img
                src=".../../../assets/svg-icons/build-report-image.svg"
                class="height-auto position-absolute left-0 top-20px" />
            </div>

            <div class="col-lg-5 padding-right-0 padding-top-50">
              <div class="row">
                <div
                  class="box-shadow bg-grey-1 padding-15 color-black font-weight-normal w-100pc">
                  {{
                    "build_your_own_reports_for_analysis_and_built_formats_to_transfer_data_to_any_accounting_system"
                      | translate : param
                  }}
                  <h6
                    class="margin-top-15 font-weight-bold color-green text-uppercase text-center">
                    {{
                      "liabilityReport_available_for_trial_now"
                        | translate : param
                    }}
                  </h6>
                </div>
              </div>
              <div class="row margin-top-25">
                <p
                  (click)="zoomDataExportImage()"
                  class="import-summary-info w-100pc margin-all-0 height-auto cursor-pointer padding-0 border-radius-1 position-relative"
                  id="viewReceipt">
                  <img
                    src="/assets/images/create-report.png"
                    class="border-radius-1" />
                  <span class="enlarge-img">
                    <i class="fas fa-search-plus" style="color: #30a5cf"></i>
                  </span>
                  <span class="text f-13"> </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex position-absolute bottom-0 w-100pc justify-content-center bg-white padding-15">
      <span class="margin-right-15">
        <button
          class="btn btn-primary w-100pc"
          (click)="routerNavigateToExportData()">
          {{ "label_watch_video" | translate : param }}
        </button> </span
      ><span class="margin-right-15">
        <button
          class="btn btn-primary w-100pc"
          (click)="fnSaveCartDetailsForExportReport()"
          [ngClass]="{ disabled: disableAddToCartForDataExport }"
          [disabled]="disableAddToCartForDataExport">
          {{ "add_to_cart" | translate : param }}
        </button>
      </span>
    </div>
  </div>
</div>

<div *ngIf="showZoomedDataExportImage" class="overLayDiv active"></div>
<div
  class="modal modal-lg fade lR-modal-popup show"
  style="display: block"
  id="viewReceiptModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  *ngIf="showZoomedDataExportImage">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="{{ 'Close' | translate }}"
          (click)="closeDataExportImage()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="viewReceiptLabel">
          {{
            "data_transfer_to_other_systems_build_your_own_report"
              | translate : param
          }}
        </h4>
      </div>
      <div class="modal-body position-relative">
        <div class="imgContainer height-auto">
          <span class="d-block text-center"
            ><img src="/assets/images/create-report.png" class="mx-auto"
          /></span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="overLayDiv active" *ngIf="showQRCodePanel"></div>
<div id="rightSideBar" style="display: block" *ngIf="showQRCodePanel">
  <div class="rightSidePanel onboard-complete-panel active">
    <header>
      <div class="row">
        <div class="col-lg-12 no-padding-right-left">
          <span class="header-text">{{
            "download_free_trial_msg" | translate
          }}</span>
        </div>
      </div>
    </header>
    <div class="clear"></div>
    <div class="block mt-0 padding-bottom-15 overflow-hidden heightCalc-10">
      <div class="row bg-white padding-top-0">
        <h6 class="border-bottom w-100pc padding-bottom-10 fw-600 f-22">
          {{ "onboarding_scan_qr_codes" | translate }}
        </h6>
      </div>
      <div class="row">
        <div class="col-md-6 no-padding-right-left text-right">
          <span
            ><img
              alt=""
              src="../../assets/images/web-mobile-1920w.png"
              class="heightCalc-21"
          /></span>
        </div>
        <div class="col-md-6 margin-top-30 padding-top-50">
          <div class="d-flex justify-content-center flex-column">
            <div>
              <span class="d-block text-center"
                ><img
                  alt=""
                  src="../../assets/images/qr-code.png"
                  class="w-55pc"
              /></span>
            </div>
            <div class="d-flex justify-content-center flex-row">
              <span class="margin-top-10 d-block">
                <a href="{{ storeURL.playStore }}" target="_blank"
                  ><img
                    alt=""
                    src="../../assets/images/android-app-download-button.png"
                    class="w-92pc" /></a
              ></span>
              <span class="margin-top-10 d-block">
                <a href="{{ storeURL.appStore }}" target="_blank"
                  ><img
                    alt=""
                    src="../../assets/images/iphone-app-download-button.png"
                    class="w-92pc" /></a
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <span class="text-center w-100pc d-block"
          ><button class="btn btn-primary" (click)="closeQRCodePanel()">
            {{ "close" | translate }}
          </button></span
        >
      </div>
    </div>
  </div>
</div>

<notification-panel
  [hidden]="!isNotificationPanelActive"
  (notificationsCountChange)="onNotificationsCountChange($event)"
  (closePanel)="closeNotificationPanel()"
  (openPanel)="openNotificationPanel()">
</notification-panel>

<div class="overLayDiv" *ngIf="isDisplayEssDetail"></div>
<div id="rightSideBar" *ngIf="isDisplayEssDetail">
  <div class="rightSidePanel active">
    <header
      class="py-1 d-flex justify-content-between align-items-center height-60px">
      <h2>{{ "info" | translate : param }}</h2>
    </header>
    <div class="clear"></div>
    <div class="block padding-top-10 padding-bottom-10">
      <div class="row">
        <ul class="info-panel">
          <li class="border-bottom">
            <p class="text-charcoal-black lH-25">
              <strong class="mr-1">1</strong>
              <span class="padding-left-5">
                <strong
                  >{{ "ess_pack_text2" | translate : param }}
                  <span class="eod-text-primary">{{ basicFuncEndDate }}</span
                  >{{ "ess_pack_text10" | translate : param }}
                  <span class="eod-text-primary">{{
                    basicFunctionRemainingTrialDays
                  }}</span>
                  {{ "days" | translate : param }}.</strong
                >
              </span>
            </p>
          </li>
          <li class="border-bottom">
            <p class="text-charcoal-black lH-25">
              <span class="mr-1">2</span>
              <span class="padding-left-5">
                {{ "ess_pack_text3" | translate : param }}
                <br />
                {{ "ess_pack_text44" | translate : param }}
              </span>
            </p>
          </li>
          <li class="border-bottom">
            <p class="text-charcoal-black lH-25">
              <span class="mr-1">3</span>
              <span class="padding-left-5">
                {{ "ess_pack_text4" | translate : param }}
              </span>
            </p>
          </li>
          <li class="border-bottom">
            <p class="text-charcoal-black lH-25">
              <span class="mr-1">4</span>
              <span class="padding-left-5">
                {{ "ess_pack_text5" | translate : param }}
              </span>
            </p>
          </li>
          <li class="border-bottom">
            <p class="text-charcoal-black lH-25">
              <span class="mr-1">5</span>
              <span class="padding-left-5">
                {{ "ess_pack_text6" | translate : param }}
                <span> 1<sup>st</sup> </span>
                {{ "ess_pack_text50" | translate : param }}
              </span>
            </p>
          </li>
          <li class="border-bottom">
            <p class="text-charcoal-black lH-25">
              <span class="mr-1">6</span>
              <span class="padding-left-5">
                {{ "ess_pack_text7" | translate : param }}
                <span
                  (click)="fnEssBtnClick(1)"
                  class="text-decoration-underline link eod-text-primary cursor-pointer">
                  {{ "here" | translate }}</span
                >.
              </span>
            </p>
          </li>
          <li class="border-bottom">
            <p class="text-charcoal-black lH-25">
              <span class="mr-1">7</span>
              <span class="padding-left-5">
                {{ "ess_pack_text8" | translate : param }}
                <span
                  (click)="fnEssBtnClick(2)"
                  class="text-decoration-underline link eod-text-primary cursor-pointer">
                  {{ "here" | translate }}</span
                >.
              </span>
            </p>
          </li>
          <li class="border-bottom">
            <p class="text-charcoal-black lH-25">
              <span class="mr-1">8</span>
              <span class="padding-left-5">
                {{ "ess_pack_text9" | translate : param }}
                <span
                  (click)="fnEssBtnClick(3)"
                  class="text-decoration-underline link eod-text-primary cursor-pointer">
                  {{ "here" | translate }}</span
                >.
              </span>
            </p>
          </li>
        </ul>
      </div>
    </div>

    <div
      class="d-flex position-absolute bottom-0 w-100pc justify-content-center bg-white padding-15">
      <span class="margin-right-15">
        <button
          type="button"
          class="btn btn-outline-primary bg-white fw-600 height-38px"
          (click)="dismissEssPack()">
          {{ "close" | translate : param }}
        </button>
      </span>
      <span class="margin-right-0" *ngIf="!claims?.isBasicFunctionPurchased">
        <button
          type="button"
          class="btn btn-primary"
          (click)="fnRedirectToEssPack()">
          {{ "subscribe" | translate : param }}
        </button>
      </span>
    </div>
  </div>
</div>

<!-- this panel should be place in last -->
<app-basicFunctionTrialEndsAlertPanel
  *ngIf="this.isOrgBasicFunctionTrialEndsAlert">
</app-basicFunctionTrialEndsAlertPanel>

<marketing-popup
  *ngIf="recallExportedExpensesMarketingPopup"
  [data]="recallExportedExpensesMarketingPopupModel"
  (closePanel)="closeMarketingPopup()">
</marketing-popup>
