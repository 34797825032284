<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel w-80pc d-flex flex-column active" >
        <header class="py-1 d-flex justify-content-between align-items-center height-60px">
            <h2>{{ "receipt" | translate }}</h2>
            <div>
                <button type="button" class="btn eod-btn-outline-warning height-38px" (click)="fnClosePanel()">
                    {{ 'label_close' | translate }}
                </button>
            </div>
        </header>

        <div  class="heightCalc-4 overflow-auto">
            <div class="row mt-2" >
                    <div  class="col-md-6 pr-2">
                        
                        <div class="position-relative block p-0">  
                            <div class="d-flex justify-content-between py-3 px-2 border-2px">
                                <h6 class="mb-0">{{ 'original_receipt' | translate }}</h6> <h6 class="mb-0">{{"submitted_date" | translate}}:{{originalReceiptInfo[0]?.receiptDate|date:'dd-MMM-yyy'}}</h6>
                            </div>
                            <div class="receipt-container mx-auto width-auto">
                                <app-view-form-receipt [totalReceipts]="originalReceiptInfo"
                                [readOnlyMode]= "true"
                                [claimType]="claimType"
                                 [isClaimPage]="false" [isHideDefaultPdfPanel]="true">
                               </app-view-form-receipt>
                            </div>
                        </div>  
                    </div>
        
                    <div class="col-md-6 pl-2 heightCalc-5 overflow-auto">
                        <div class="position-relative block p-0 ">
                            <div class="d-flex justify-content-between py-3 px-2 border-2px">
                                <h6 class="eod-text-danger mb-0">{{"duplicate_receipts" | translate}}</h6> <h6 class="eod-text-danger mb-0">{{"submitted_date" | translate}}: {{duplicateReceiptInfo[0]?.receiptDate|date:'dd-MMM-yyy'}}</h6>
                            </div>
                            <div class="receipt-container mx-auto width-auto">
                                <app-view-form-receipt [totalReceipts]="duplicateReceiptInfo"
                                [readOnlyMode]= "true"
                                [claimType]="claimType"
                                 [isClaimPage]="false" [isHideDefaultPdfPanel]="true">
                               </app-view-form-receipt>
                            </div>
                        </div>
                    </div>
            </div>
       </div>
       
    </div>
</div>