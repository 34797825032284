<!-- <div class="col-md-6 padding-right-0"> -->
<div class="row">
    <div class="col-md-12 no-padding-right-left">
        <div class="block mt-1 padding-bottom-25" style="min-height:calc(100vh - 8em)">
            <div class="row">
                <p class="f-20 w-100pc color-blue text-center padding-bottom-15">
                    {{ baseCategoryNameModel }}
                </p>
                <div class="imgContainer text-center mx-auto">
                    <span *ngIf="baseCategoryId == baseCategoryTypeEnum.Entertainment" class="d-block text-center">
                        <img src="/assets/images/entertainment.png" alt="Mobile Image template"
                            class="w-40pc img-fluid" />
                    </span>
                    <span *ngIf="baseCategoryId == baseCategoryTypeEnum.Generic" class="d-block text-center">
                        <img src="/assets/images/generic_Category.png" alt="Mobile Image template"
                            class="w-40pc img-fluid" />
                    </span>
                    <span *ngIf="baseCategoryId == baseCategoryTypeEnum.Hotel" class="d-block text-center">
                        <img src="/assets/images/hotel_lodgings.png" alt="Mobile Image template"
                            class="w-40pc img-fluid" />
                    </span>
                    <span *ngIf="baseCategoryId == baseCategoryTypeEnum.Mileage" class="d-block text-center">
                        <img src="/assets/images/manual_mileage.png" alt="Mobile Image template"
                            class="w-40pc img-fluid" />
                    </span>
                    <span *ngIf="baseCategoryId == baseCategoryTypeEnum.MileageGPS" class="d-block text-center">
                        <img src="/assets/images/mileageGps.png" alt="Mobile Image template" class="w-40pc img-fluid" />
                    </span>
                    <span *ngIf="baseCategoryId == baseCategoryTypeEnum.MileageGoogle" class="d-block text-center">
                        <img src="/assets/images/mileage_google.png" alt="Mobile Image template"
                            class="w-40pc img-fluid" />
                    </span>
                    <span *ngIf="!baseCategoryId" class="d-block text-center">
                        <img src="/assets/images/No-option-selected.png" alt="Mobile Image template"
                            class="w-40pc img-fluid" />
                    </span>
                </div>
            </div>
            <div class="row manage-category--activeCategories" *ngIf="orgCategoryListByBaseCatId.length > 0">
                <p class="padding-top-15" style="text-align: center; width: 100%;"
                    (click)="isActiveCategoryForTemplate = !isActiveCategoryForTemplate">
                    {{ "manageCat_templateUsing_msg1" | translate: param}}
                    <a class="color-blue-imp">{{ orgCategoryListByBaseCatId.length }}
                        {{"manageCat_templateUsing_msg2" | translate: param}}
                    </a>
                </p>
                <div class="manage-category--activeCategoriesList" *ngIf="isActiveCategoryForTemplate">
                    <ul>
                        <p (click)="isActiveCategoryForTemplate = !isActiveCategoryForTemplate">
                            <span>
                                {{"manageCat_templateUsing_msg2" | translate: param}}
                            </span>
                            <span>
                                <i class="fal fa-times-circle"></i>
                            </span>
                        </p>

                        <li *ngFor="let item of orgCategoryListByBaseCatId">
                            {{ item.expenseCategoryName }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- </div> -->