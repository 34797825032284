import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { ApiRequest, IApiresponse } from 'src/app/_models';
import { LocationCommonDetail } from 'src/app/_models/claimant-on-web/claimant-on-web';
import { IOrganisationGooglePackageUpdateCallsRequest, OrganisationGooglePackageUpdateCallsRequest } from 'src/app/_models/google-mapping/google-mapping';
import { ClaimsService } from 'src/app/_services/common/claims.service';
import { GoogleMappingService } from 'src/app/_services/google-mapping/google-mapping.service';
import { environment } from 'src/environments/environment';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';
import { TranslateService } from "@ngx-translate/core";
import { Claims } from "src/app/_models/common/claims";

@Component({
  selector: 'formly-google-location-input',
  templateUrl: "./formly-google-location-input.component.html"
})
export class FormlyGoogleLocationInputComponent extends FieldType implements OnInit, OnDestroy {

  claimsSubscription: Subscription;
  claims: Claims;

  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;
  fieldKey: string;

  queryWait: boolean = false;
  googleDynamicAddressList: any;
  isGoogleDropdownVisible: boolean = false;
  isHomeOfcDropdownVisible: boolean = false;
  isGoogleAddressNotFound: boolean = false;
  countOfGoogleCalls: number = 0;

  gm_lable: string;
  modelKey: string;

  constructor(private dynamicFormService: DynamicFormService,
    private claimsService: ClaimsService,
    private googleMappingService: GoogleMappingService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef) {
    super();
    this.claimsSubscription = this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
    });
  }

  ngOnInit() {
    if (this.field.key) {
      this.fieldKey = this.field.key.toString();
    }
    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
    });
    this.gm_lable = ' ' + this.translate.instant('gm_message');
    this.modelKey = this.field.templateOptions.modelKey;
    this.fnSetGoogleCallsMsg()
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
    if (this.claimsSubscription) {
      this.claimsSubscription.unsubscribe();
    }
  }
  fnSetGoogleCallsMsg() {
    if (this.formControl.value) {
      this.isGoogleNotesDisplay = false;
      this.isToDelIconDisplay = true;
    }
  }
  onTextInput() {
    this.model[this.modelKey.toString()].location = this.formControl.value;
    if (this.to.change) {
      this.to.change(this.field, this.formControl.value);
    }

  }
  isCharLimit: boolean = false;
  fnKeypress(value) {
    if (this.isToDelIconDisplay) {
      return false;
    }
    else {
      this.isCharLimit = false;
      if (this.model.googleCallsInfo
        && Number(this.model.googleCallsInfo.limitChar) > 0
        && value.length >= Number(this.model.googleCallsInfo.limitChar)) {
        this.isCharLimit = true;
        return false;
      }
      return true;
    }

  }
  fnKeyDown() {
    if (this.isToDelIconDisplay) {
      return false;
    }
    else {
      return true;
    }
  }
  isToDelIconDisplay: boolean = false;
  fnSearchAddress(value) {
    if (value.length == 0) {
      this.isToDelIconDisplay = false;
    }
    if (this.model.googleCallsInfo
      && Number(this.model.googleCallsInfo.limitChar) > 0
      && value.length <= Number(this.model.googleCallsInfo.limitChar)) {
      this.isCharLimit = false;
    }
    if (!this.isCharLimit) {
      this.isHomeOfcDropdownVisible = false;
      this.model[this.modelKey.toString()].placeId = null;
      this.model[this.modelKey.toString()].location = this.formControl.value;

      if (!this.queryWait) {
        this.queryWait = true;
        setTimeout(() => {
          this.fnGetFromAutocompletePredictions();
          this.queryWait = false;
        }, 200);
      }
    }
    this.cdr.detectChanges();
  }

  private fnGetFromAutocompletePredictions() {

    if (this.model[this.modelKey.toString()].location.length < 6) {
      this.googleDynamicAddressList = null;
      if (this.model[this.modelKey.toString()].location.length > 0) {
        this.isGoogleAddressNotFound = true;
        this.isHomeOfcDropdownVisible = false;
      }
      else {
        if (this.model.triangulationRule) {
          this.isHomeOfcDropdownVisible = true;
        }
        this.isGoogleAddressNotFound = false;
        this.isGoogleDropdownVisible = false;
      }
    }
    if (this.model[this.modelKey.toString()].location.length != 6 && this.model[this.modelKey.toString()].location.length < 10) {
      return;
    }
    this.fnGetPlacePredictions(this.model[this.modelKey.toString()].location)
      .then(data => {
        this.googleDynamicAddressList = data;
        this.isGoogleDropdownVisible = this.googleDynamicAddressList != null ? true : false;
        this.isGoogleAddressNotFound = this.googleDynamicAddressList == null ? true : false;
        this.isHomeOfcDropdownVisible = false;
        this.countOfGoogleCalls++;
      })
      .catch(err => {
        this.isGoogleDropdownVisible = false;
        this.isGoogleAddressNotFound = true;
        this.isHomeOfcDropdownVisible = false;
      });
  }

  private fnGetPlacePredictions(query: string): Promise<any> {
    let autocompleteSrv = new google.maps.places.AutocompleteService();
    return new Promise((resolve, reject) => {
      autocompleteSrv.getPlacePredictions({
        types: ['geocode'],
        input: query
      }, function (predictions, status) {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          resolve(predictions);
        } else {
          reject(status);
        }
      });
    });
  }
  fnOnInputClick() {
    if (this.model.triangulationRule) {
      if ((!this.googleDynamicAddressList || this.googleDynamicAddressList == null
        || this.googleDynamicAddressList.length == 0) && (!this.formControl.value || this.formControl.value == '')) {
        this.isGoogleDropdownVisible = false;
        this.isGoogleAddressNotFound = false;
        this.isHomeOfcDropdownVisible = true;
      }
    }
  }
  fnCloseGoogleDropdown() {
    setTimeout(() => {
      this.isGoogleDropdownVisible = false;
      this.isGoogleAddressNotFound = false;
      this.isHomeOfcDropdownVisible = false;
    }, 300);


    if (!this.isToDelIconDisplay && this.countOfGoogleCalls > 0) {
      this.fnUpdateOrganisationGooglePackageCalls();
    }
  }

  fnUpdateOrganisationGooglePackageCalls() {
    let request = new ApiRequest<IOrganisationGooglePackageUpdateCallsRequest>("UpdateOrganisationGooglePackageCalls", environment.applicationVersion, environment.requestId);
    let requestInfo = new OrganisationGooglePackageUpdateCallsRequest();
    requestInfo.OrganisationId = this.claims.OrgId;
    requestInfo.CallsUsed = this.countOfGoogleCalls;
    requestInfo.UpdatedBy = this.claims.UserId;
    request.requestInfo = requestInfo;
    this.googleMappingService.UpdateOrganisationGooglePackageCalls(request).subscribe((res: IApiresponse<any>) => {
      this.countOfGoogleCalls = 0;
    });
  }
  isGoogleNotesDisplay: boolean = true;
  fnSetAddress(place: object) {
    this.formControl.setValue(place['description']);
    this.model[this.modelKey.toString()].location = place['description'];
    this.model[this.modelKey.toString()].placeId = place['place_id'];
    this.isGoogleNotesDisplay = false;
    this.isGoogleDropdownVisible = false;
    this.googleDynamicAddressList = null;
    this.isGoogleAddressNotFound = false;
    this.isHomeOfcDropdownVisible = false;
    this.fnIsDelIconDisplay();
    if (this.to.change) {
      this.to.change(this.field, this.formControl.value);
    }
    this.cdr.detectChanges();
  }
  fnSetAddressFromTriangulation(location: LocationCommonDetail) {
    if (location) {
      this.formControl.setValue(location.address);
      this.model[this.modelKey.toString()].location = location.address;
      this.model[this.modelKey.toString()].placeId = location.placeId;
      this.isGoogleNotesDisplay = false
      this.isGoogleDropdownVisible = false;
      this.googleDynamicAddressList = null;
      this.isGoogleAddressNotFound = false;
      this.isHomeOfcDropdownVisible = false;
      this.fnIsDelIconDisplay();
      if (this.to.change) {
        this.to.change(this.field, this.formControl.value);
      }
      this.cdr.detectChanges();
    }

  }
  fnDeleteText() {
    this.model[this.modelKey.toString()].placeId = null;
    this.model[this.modelKey.toString()].location = null;
    this.isGoogleNotesDisplay = true
    this.formControl.setValue(null);
    if (this.to.change) {
      this.to.change(this.field, this.formControl.value);
    }
    this.cdr.detectChanges();
    this.isToDelIconDisplay = false;
  }
  fnIsDelIconDisplay() {
    this.isToDelIconDisplay = true;
  }

  fnSearchPlacePrediction() {
    this.fnGetPlacePredictions(this.model[this.modelKey.toString()].location)
      .then(data => {
        this.googleDynamicAddressList = data;
        this.isGoogleDropdownVisible = this.googleDynamicAddressList != null ? true : false;
        this.isGoogleAddressNotFound = this.googleDynamicAddressList == null ? true : false;
        this.isHomeOfcDropdownVisible = false;
        this.countOfGoogleCalls++;
      })
      .catch(_err => {
        this.isGoogleDropdownVisible = false;
        this.isGoogleAddressNotFound = true;
        this.isHomeOfcDropdownVisible = false;
      });
  }
  isOfficeAddressPanel: boolean;
  closeOfficeAddPanel(location) {
    if (location) {
      this.formControl.setValue(location.officeAddress);
      this.model[this.modelKey.toString()].location = location.officeAddress;
      this.model[this.modelKey.toString()].placeId = location.placeId;
      this.isGoogleNotesDisplay = false
      this.isGoogleDropdownVisible = false;
      this.googleDynamicAddressList = null;
      this.isGoogleAddressNotFound = false;
      this.isHomeOfcDropdownVisible = false;
      this.fnIsDelIconDisplay();
      if (this.to.change) {
        this.to.change(this.field, this.formControl.value);
      }
      this.cdr.detectChanges();
    }
    this.isOfficeAddressPanel = false;
  }
}
