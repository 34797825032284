import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { TranslateService } from "@ngx-translate/core";
import { Claims } from "src/app/_models/common/claims";
import { OrgFeedbackTypes } from "src/app/_models/enum/solo-enum";
import { Subscription } from "rxjs";
import { OrganisationSettingService } from "src/app/_services/organisation-setting/organisation-setting.service";
import { ToastrService } from "ngx-toastr";
import { OrganisationFeedback } from "src/app/_models/feedback/feedback";

@Component({
  selector: "feedback-panel",
  templateUrl: "./feedback-panel.component.html",
  styleUrls: ["./feedback-panel.component.scss"]
})
export class FeedbackPanelComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  type: number;

  @Output()
  closePanel = new EventEmitter();

  claims: Claims;
  claimSubscription: Subscription;
  userName: string;
  userEmail: string;

  isOnboardingFeedback: boolean = false;
  isTrialFeedback: boolean = false;

  isYesChecked: boolean = false;
  isNoChecked: boolean = false;

  feedbackTypeText: string = "";
  onboardingFeedbackText: string = "";
  showCalendly = false;


  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
    private claimsService: ClaimsService,
    private organisationSettingService: OrganisationSettingService,

  ) {
    this.claimSubscription = this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
      if (claims && claims.UserId) {
        this.userName = this.claims.FinanceManager;
        this.userEmail = this.claims.LoginId;
      }
    });
  }

  ngOnInit() {
    this.initializePanel();
  }

  ngOnDestroy() {
    this.claimSubscription.unsubscribe();
  }

  ngOnChanges() {

  }

  initializePanel() {
    if (this.type == OrgFeedbackTypes.OnboardingFeedback || this.type == OrgFeedbackTypes.TrialFeedback) {
      this.organisationSettingService.isFeedbackSubmitted(this.type).subscribe(result => {
        if (!result) {
          if (this.type == OrgFeedbackTypes.OnboardingFeedback) {
            this.isOnboardingFeedback = true;
          }
          else if (this.type == OrgFeedbackTypes.TrialFeedback) {
            this.isTrialFeedback = true;
          }
        }
        else {
          this.closeFeedbackPanel();
        }
      });
    }
  }

  onOptionChange(status) {
    this.isYesChecked = status;
    this.isNoChecked = !status;

    if (this.isOnboardingFeedback && this.isNoChecked) {
      this.showCalendly = true;
    }
  }

  onSubmitFeedback() {

    if (!this.isNoChecked && !this.isYesChecked) {
      this.toastr.error(this.translate.instant('feedback_selectoption'));
      return;
    }

    if (this.type == OrgFeedbackTypes.OnboardingFeedback) {
      this.feedbackTypeText = this.isYesChecked ? this.translate.instant("onboarding_feedback_yes") : this.translate.instant("onboarding_feedback_no");

     

    }
    else if (this.type == OrgFeedbackTypes.TrialFeedback) {
      this.feedbackTypeText = this.isYesChecked ? this.translate.instant("trial_feedback_yes") : this.translate.instant("trial_feedback_no");
    }

    const model = new OrganisationFeedback(this.userName, this.userEmail, this.type, this.feedbackTypeText, this.isYesChecked, this.onboardingFeedbackText)
    this.organisationSettingService.SubmitOrgFeedback(model).subscribe(result => {
      if (result) {
        if (this.isYesChecked) {
          this.toastr.success(this.translate.instant("feedback_submitsuccess"))
        }
        this.closeFeedbackPanel();
      }
      else {
        this.toastr.error(this.translate.instant("feedback_submiterror"));
      }
    });

  }

  closeFeedbackPanel() {
    this.closePanel.emit();
  }

}
